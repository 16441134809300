import { ChangeEvent, useEffect, useState } from "react";
import { ReactComponent as DropdownIcon } from "src/assets/icons/dropdown.svg";
import DropdownContent from "./DropdownContent";
import { IoMdClose } from "react-icons/io";
import LoadingBarScreen from "src/components/LottieAnimations/LoadingBarScreen";
import DropDownLoader from "src/components/LottieAnimations/DropdownLoader";
import { IDialect, IDialects } from "./DialectForm";
interface IDropDown {
  items: IDialects[];
  selectedItem: IDialects;
  setSelectedItem: (text: IDialects) => void;
  handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  dialectQuery: string;
  loading: boolean;
  open: boolean;
  setOpen: any;
}
export default function DialectDropdown({
  items,
  selectedItem,
  setSelectedItem,
  handleInputChange,
  dialectQuery,
  loading,
  open,
  setOpen,
}: IDropDown) {
  const [dialects, setDialects] = useState<IDialect[]>([]);
  const [selectedDialect, setSelectedDialect] = useState<IDialect>();
  useEffect(() => {
    setSelectedDialect({
      value: selectedItem.match,
      label:
        selectedItem.type == "STATE"
          ? `${selectedItem.match}, ${selectedItem.type.toLowerCase()}`
          : `${selectedItem.match}, ${selectedItem.type.toLowerCase()} (${
              selectedItem.state
            })`,
    });
    const mappedDialects = items.map((dialect) => ({
      value: dialect.match,
      label:
        dialect.type == "STATE"
          ? `${dialect.match}, ${dialect.type.toLowerCase()}`
          : `${dialect.match}, ${dialect.type.toLowerCase()} (${
              dialect.state
            })`,
    }));
    setDialects(mappedDialects);
    if (items.length > 0) setOpen(true);
  }, [items.length, selectedItem]);

  const handleSelect = (group: IDialect) => {
    const dialect = items.find((item) => item.match == group.value);
    if (dialect) {
      setSelectedItem(dialect);
    }
    setTimeout(() => {
      setOpen(false);
    }, 5);
  };

  const handleToggle = () => {
    setOpen(!open);
  };
  const handleClearOption = () => {
    setSelectedItem({
      towns: [],
      type: "",
      match: "",
      state: "",
    });
  };
  return (
    <div>
      <div>
        {selectedDialect?.value ? (
          <div
            onClick={handleToggle}
            style={{
              borderColor: "#F4F4F4",
              backgroundColor: "#F2F2F280",
            }}
            className="mt-3 h-16 rounded-3xl border-2 py-5 px-4  flex items-center justify-between cursor-pointer"
          >
            <p className="text-xl capitalize font-medium">
              <span>{selectedDialect.label.split(",")[0]}</span>,
              <span className=" text-secondary-light text-[16px] ml-1 inline-block">
                {selectedDialect.label.split(",")[1]}
              </span>
            </p>
            <div onClick={handleClearOption}>
              <IoMdClose className="text-primary text-2xl cursor-pointer font-semibold" />
            </div>
          </div>
        ) : (
          <div>
            <input
              type="text"
              value={dialectQuery}
              onChange={handleInputChange}
              data-test="d2pDialectInput"
              placeholder="Start typing to see matching options"
              className="mt-3 h-16 rounded-3xl border-2 w-full max-w-md py-5 px-4 text-[16px]"
              style={{
                borderColor: "#F4F4F4",
                backgroundColor: "#F2F2F280",
                outline: "none",
              }}
            />
          </div>
        )}
      </div>

      <DropdownContent open={open}>
        {loading ? (
          <DropDownLoader />
        ) : dialects.length > 0 ? (
          dialects.map((a) => (
            <p
              key={a.label}
              className={`leading-5 text-lg capitalize font-medium p-3 cursor-pointer rounded-xl hover:bg-primary-pale transition-colors duration-300 `}
              onClick={() => {
                handleSelect(a);
              }}
              data-test="d2pDialectOptions"
            >
              <span>{a.label.split(",")[0]}</span>,
              <span className=" text-secondary-light text-[16px] ml-1 inline-block">
                {a.label.split(",")[1]}
              </span>
            </p>
          ))
        ) : (
          <p
            className={`leading-5 text-lg capitalize font-medium p-3 cursor-pointer rounded-xl hover:bg-primary-pale transition-colors duration-300 `}
          >
            No record found
          </p>
        )}
      </DropdownContent>
    </div>
  );
}
