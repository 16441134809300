import { useMemo } from "react";
import { useDirectPayContext } from "src/context/DirectPayContext";
import { routes } from "src/Routes";

export default function useD2POnboarding() {
  const {
    state: { onBoarding },
  } = useDirectPayContext();
  const route = useMemo(() => {
    if (onBoarding?.onboarding) {
      if (
        onBoarding?.onboarding.status === "PENDING" &&
        !onBoarding?.onboarding.isLegacyCustomer
      ) {
        if (
          onBoarding?.onboarding?.leadFunnel === "direct-to-pay-flow-1" ||
          onBoarding?.onboarding?.leadFunnel === "direct-to-pay-flow-3"
        ) {
          if (onBoarding?.tutors && onBoarding?.tutors.length > 0) {
            return routes.DIRECT_TO_PAY_MEET_TUTORS.url;
          }
        } else {
          if (onBoarding?.onboarding.paymentStatus === "COMPLETE") {
            if (
              !onBoarding?.onboarding.ageGroup ||
              !onBoarding?.onboarding.personality ||
              onBoarding?.onboarding.reasonsForLearning?.length === 0
            ) {
              return (
                routes.DIRECT_TO_PAY_GET_STARTED_B.url +
                `?lang=${onBoarding?.onboarding.subject}`
              );
            } else {
              return routes.DIRECT_TO_PAY_MEET_TUTORS_B.url;
            }
          } else {
            return routes.DIRECT_TO_PAY_WELCOME_B.url + "/payment-plans";
          }
        }
      }
    } else {
      return routes.BOOK_A_LESSON_VIA_ADMIN.url;
    }
    return null;
  }, [onBoarding]);
  return { route };
}
