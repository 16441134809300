/* eslint-disable import/prefer-default-export,class-methods-use-this */

// application
import {
  ITutorEditableProfileDetails,
  ITutorProfile,
  ITutorSearchResult,
  ITutorSettings,
  ProvideLessonFeedback,
} from "src/interfaces/tutor";
import {
  getTutorSearchResults,
  getTutorProfileDetails,
  postTutorProfileDetails,
  postTutorBio,
  postTutorVideoShowcase,
  postTutorAvatar,
  getTutorAccountSettings,
  postTutorAccountSettings,
  getLessonRequests,
  postBookingRequestResponse,
  getConfirmedLessons,
  getTutorAvailabilityAndBookings,
  getConfirmedLessonsViaAdmin,
  getConfirmedLessonsHistoryViaAdmin,
  getTutorReport,
  postUpdateExerciseStatus,
  postUpdateInterviewStatus,
  getTutorReportByDateRange,
  postTutorAvailability,
  getTutorPaymentInfo,
  provideLessonFeedback,
  getLessonFeedback,
  getFeedbacks,
  getTutorFeedbackByDateRange,
  getTutorPerformance,
  markRatingsLinkAsCopied,
  getConfirmedLessonsViaAdminSSE,
  getStudentNotes,
} from "src/server/endpoints/tutor";

export class TutorApi {
  static getTutorSearchResults(): Promise<ITutorSearchResult[]> {
    return getTutorSearchResults();
  }
  static getTutorProfileDetails(
    tutorId: string | undefined
  ): Promise<ITutorProfile> {
    return getTutorProfileDetails(tutorId);
  }
  static postTutorProfileDetails(
    tutorId: string | undefined,
    tutorDetails: ITutorEditableProfileDetails
  ): Promise<any> {
    return postTutorProfileDetails(tutorId, tutorDetails);
  }
  static postTutorAvailability(
    userId: string,
    tutorAvailability: any,
    desiredTeachingHours: any
  ): Promise<any> {
    return postTutorAvailability(
      userId,
      tutorAvailability,
      desiredTeachingHours
    );
  }
  static postTutorBio(
    tutorId: string | undefined,
    tutorBio: string
  ): Promise<any> {
    return postTutorBio(tutorId, tutorBio);
  }
  static postTutorVideoShowcase(
    tutorId: string | undefined,
    videoUrl: string
  ): Promise<any> {
    return postTutorVideoShowcase(tutorId, videoUrl);
  }
  static postTutorAvatar(
    tutorId: string | undefined,
    imagData: any
  ): Promise<any> {
    return postTutorAvatar(tutorId, imagData);
  }
  static postUpdateExerciseStatus(demoId: string): Promise<any> {
    return postUpdateExerciseStatus(demoId);
  }
  static markRatingsLinkAsCopied(lessonId: string, body: any): Promise<any> {
    return markRatingsLinkAsCopied(lessonId, body);
  }
  static postUpdateInterviewStatus(demoId: string): Promise<any> {
    return postUpdateInterviewStatus(demoId);
  }
  static getTutorAccountSettings(tutorId: string | undefined): Promise<any> {
    return getTutorAccountSettings(tutorId);
  }
  static getTutorPaymentInfo(tutorId: string): Promise<any> {
    return getTutorPaymentInfo(tutorId);
  }
  static postTutorAccountSettings(
    tutorId: string | undefined,
    settings: ITutorSettings
  ): Promise<any> {
    return postTutorAccountSettings(tutorId, settings);
  }
  static getLessonRequests(tutorId: string): Promise<any[]> {
    return getLessonRequests(tutorId);
  }
  static getConfirmedLessons(tutorId: string): Promise<any[]> {
    return getConfirmedLessons(tutorId);
  }
  static getTutorReport(tutorId: string): Promise<any> {
    return getTutorReport(tutorId);
  }
  static getTutorPerformance(tutorId: string): Promise<any> {
    return getTutorPerformance(tutorId);
  }
  static getTutorReportByDateRange(
    tutorId: string,
    startDate: Date,
    endDate: Date
  ): Promise<any> {
    return getTutorReportByDateRange(tutorId, startDate, endDate);
  }
  static getTutorFeedbackByDateRange(
    tutorId: string,
    startDate: Date,
    endDate: Date
  ): Promise<any> {
    return getTutorFeedbackByDateRange(tutorId, startDate, endDate);
  }
  static postBookingRequestResponse(
    bookingRequestId: string,
    body: any
  ): Promise<any> {
    return postBookingRequestResponse(bookingRequestId, body);
  }
  static getTutorAvailabilityAndBookings(tutorId: string): Promise<any> {
    return getTutorAvailabilityAndBookings(tutorId);
  }
  static getConfirmedLessonsViaAdmin(tutorId: string): Promise<any> {
    return getConfirmedLessonsViaAdmin(tutorId);
  }
  static getConfirmedLessonsViaAdminSSE(tutorId: string): EventSource {
    return getConfirmedLessonsViaAdminSSE(tutorId);
  }
  static getConfirmedLessonsHistoryViaAdmin(tutorId: string): Promise<any> {
    return getConfirmedLessonsHistoryViaAdmin(tutorId);
  }
  static provideLessonFeedback(body: ProvideLessonFeedback): Promise<any> {
    return provideLessonFeedback(body);
  }
  static getLessonFeedback(lessonId: string): Promise<any> {
    return getLessonFeedback(lessonId);
  }
  static getFeedbacks(): Promise<any> {
    return getFeedbacks();
  }
  static getStudentNotes(studentId: string): Promise<any> {
    return getStudentNotes(studentId);
  }
}
