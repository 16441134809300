import Revolut from "./Revolut";

const Checkout = () => {
  return (
    <>
      <Revolut />
    </>
  );
};

export default Checkout;
