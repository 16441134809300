import { useEffect, useMemo, useState } from "react";

import { useStateValue } from "src/context/StateProvider";
import { getClientCurrency } from "src/services/currency";
import { IClientCurrencyData } from "src/interfaces/currency";
import { IPackageType } from "./PaymentPlan";
import { RiVipCrownLine } from "react-icons/ri";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { BsCheck } from "react-icons/bs";
import { getPaymentPlanInfo } from "src/static-data/direct-to-pay/paymentPlaninfo";
import { useDirectPayContext } from "src/context/DirectPayContext";
import formatPackagePrice from "src/utils/helper/formatPackagePrice";

type selectPaymentFunction = (lessonPackage: IPackageType) => void;
export default function PaymentPlanCard({
  paymentPlans,
  selectPaymentBundle,
  selected,
}: {
  paymentPlans: any;
  selectPaymentBundle: selectPaymentFunction;
  selected: boolean;
}) {
  const [{ clientIpCountry }] = useStateValue();
  const [currency, setCurrency] = useState<IClientCurrencyData | null>(null);
  const [isMoreInfoOpen, setIsMoreInfoOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const {
    state: { lessonFrequency },
  } = useDirectPayContext();
  console.log(currency, clientIpCountry);

  useEffect(() => {
    if (clientIpCountry) {
      let cp = getClientCurrency(clientIpCountry);
      switch (cp.currencyCode) {
        case "USD":
        case "CAD":
        case "GBP":
          setCurrency(cp);
          break;
        default:
          setCurrency({
            symbol: "$",
            fromNairaValue: 0.0024,
            fromPoundValue: 1.34,
            fromDollarValue: 1,
            currencyCode: "USD",
          });
      }
    }
  }, [clientIpCountry]);

  const toggleMoreInfo = () => {
    setIsMoreInfoOpen(!isMoreInfoOpen);
  };

  const paymentPlanInfo = useMemo(() => {
    return getPaymentPlanInfo(
      paymentPlans.type,
      paymentPlans.numberOfLessons,
      lessonFrequency
    );
  }, [paymentPlans, lessonFrequency]);

  return (
    <div
      className={`px-4 pb-4 ${!isMoreInfoOpen && "sm:h-[310px] lg:h-[350px]"} ${
        paymentPlans.type.includes("Beginner & Elementary Package") ||
        paymentPlans.type.includes(
          "Intermediate + Upper Intermediate Package (60 Lessons)"
        )
          ? "gray-card-rounded"
          : "white-card-more-rounded"
      } ${selected ? " border-primary" : " border-gray-200"}`}
    >
      {paymentPlans.discount === 16 ? (
        <div className="flex justify-end">
          <div className="bg-yellow-500 py-2 px-4 rounded-lg w-38 -mt-6 text-white">
            <p className="font-semibold">{paymentPlans.discount}% Discount</p>
          </div>
        </div>
      ) : paymentPlans.discount === 33 ? (
        <div className="flex justify-end">
          <div className="bg-secondary py-2 px-4 rounded-lg w-38 -mt-6 text-white">
            <p className="font-semibold">{paymentPlans.discount}% Discount</p>
          </div>
        </div>
      ) : paymentPlans.discount === 41 ? (
        <div className="flex justify-end">
          <div className="bg-green-500 py-2 px-4 rounded-lg w-38 -mt-6 flex gap-2 items-center text-white">
            <RiVipCrownLine size={18} />
            <p className="font-semibold">{paymentPlans.discount}% Discount</p>
          </div>
        </div>
      ) : paymentPlans.type === "Two Taster Lessons" ? (
        <div className="flex justify-end">
          <div className="bg-purple-500 py-2 px-4 rounded-lg w-38 -mt-6 flex gap-2 items-center text-white">
            <p className="font-semibold">One free</p>
          </div>
        </div>
      ) : (
        <div className="pb-4"></div>
      )}

      <div className="flex flex-col h-full">
        {/* Beginner & Elementary Package */}
        {/* tutor details */}

        <div className="flex gap-3 justify-between text-primary pt-4 pb-2 ">
          {/* Package */}
          <p className="text-primary font-medium w-60 text-lg">
            {paymentPlans.type}
          </p>
          {!isMoreInfoOpen && (
            <MdKeyboardArrowDown
              onClick={toggleMoreInfo}
              className="text-primary cursor-pointer font-semibold"
              size={30}
            />
          )}
          {isMoreInfoOpen && (
            <MdKeyboardArrowUp
              onClick={toggleMoreInfo}
              className="text-primary cursor-pointer"
              size={30}
            />
          )}
        </div>
        <div className=" py-2">
          <p className="text-primary-medium font-bold text-3xl">
            {currency?.currencyCode === "USD"
              ? `${paymentPlans.perLessonRateUSD}`
              : currency?.currencyCode === "GBP"
              ? ` ${paymentPlans.perLessonRateGBP}`
              : currency?.currencyCode === "CAD"
              ? ` ${paymentPlans.perLessonRateCAD}`
              : `${paymentPlans.perLessonRateUSD}`}
            <span className="text-base">/Session</span>
          </p>
        </div>
        <div
          className={
            paymentPlans.type === "Beginner & Elementary Package"
              ? " pb-2"
              : paymentPlans.type ===
                "Intermediate + Upper Intermediate Package (60 Lessons)"
              ? "pb-1"
              : paymentPlans.type === "Monthly Package"
              ? "pb-6"
              : paymentPlans.type === "Beginner Package"
              ? "pb-4"
              : "pb-0"
          }
        >
          <p className="text-gray-500 font-semibold">Total Price</p>
          <p className="text-primary-medium font-semibold">
            {formatPackagePrice(
              currency?.currencyCode || "USD",
              paymentPlans.type === "Monthly Package"
                ? paymentPlans[
                    "transactionAmount" + currency?.currencyCode || "USD"
                  ] * lessonFrequency
                : paymentPlans[
                    "transactionAmount" + currency?.currencyCode || "USD"
                  ]
            )}
          </p>
          <p className="text-primary font-medium text-md">
            {paymentPlans.plan}
          </p>
        </div>
        {isMoreInfoOpen &&
          paymentPlanInfo.map((info, index) => (
            <div
              key={index}
              className="text-primary-medium flex gap-2 pt-2 pb-2"
            >
              <div>
                <BsCheck size={25} className="text-secondary" />
              </div>
              <p className="text-sm">{info}</p>
            </div>
          ))}
        <button
          onClick={() => {
            selectPaymentBundle(paymentPlans);
            setLoading(true);
          }}
          disabled={loading}
          className={`purple-button rounded-3xl w-full mt-3 sm:mt-auto ${
            !isMoreInfoOpen && "sm:mb-5"
          }`}
          data-test="d2pChooseAPaymentBundleButton"
        >
          Get Started
        </button>
      </div>
    </div>
  );
}
