import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import ReactPlayer from "react-player";

import { IClientCurrencyData } from "src/interfaces/currency";
import { ITutorSearchResult } from "src/interfaces/tutor";
import { Context as DirectPayContext } from "src/context/DirectPayContext";
import { toast } from "react-toastify";
import {
  formatDateToNeutral,
  incrementDateWithSameLocalTime,
} from "src/utils/dateUtils";
import { useNavigate } from "react-router-dom";
import { LessonSchedule } from "src/interfaces/direct-pay";
import { lessonDurationOptions } from "src/components/DirectToPay/PaymentPlans/PaymentPlan";
import { routes } from "src/Routes";
import { addSuffix } from "src/utils/helper/numberString";
import { PaymentApi } from "src/api/payment.api";
type VideoCardType = {
  tutor: ITutorSearchResult;
  currency: IClientCurrencyData | null;

  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  setisPayment: React.Dispatch<React.SetStateAction<boolean>>;
};
export default function VideoAndBookingCard({
  tutor,
  setisPayment,
}: VideoCardType) {
  const {
    state: {
      subject,
      lessonSchedule,
      isLoading,
      onBoarding,
      lessonFrequency,
      selectedLessonScheduleId,
    },
    actions: { updateOnboarding, bookLessons, setSelectedLessonScheduleId },
  } = useContext(DirectPayContext);

  const [recurringDates, setRecurringDates] = useState<LessonSchedule[]>([]);
  const navigate = useNavigate();

  const handleBookLessonClick = async () => {
    // update onboarding

    updateOnboarding(
      { lessonSchedule, lessonFrequency },
      onBoarding.onboarding!._id
    );

    //check if its flow a or b
    if (
      lessonSchedule.length === lessonFrequency &&
      (onBoarding.onboarding?.leadFunnel === "direct-to-pay-flow-1" ||
        onBoarding.onboarding?.leadFunnel === "direct-to-pay-flow-3")
    ) {
      setisPayment(true);
    } else if (
      lessonSchedule.length === lessonFrequency &&
      (onBoarding.onboarding?.leadFunnel === "direct-to-pay-flow-2" ||
        onBoarding.onboarding?.leadFunnel === "direct-to-pay-flow-4")
    ) {
      let newSchedule;
      let rec: any;
      let duration: any;
      if (onBoarding?.learningPackage) {
        duration =
          onBoarding?.learningPackage.lessonType === "fullLesson"
            ? { ...lessonDurationOptions[0] }
            : { ...lessonDurationOptions[1] };
      } else if (onBoarding.onboarding?.learningPackage) {
        duration =
          onBoarding.onboarding!.learningPackage.lessonType === "fullLesson"
            ? { ...lessonDurationOptions[0] }
            : { ...lessonDurationOptions[1] };
      }
      if (onBoarding.onboarding) {
        newSchedule = [...lessonSchedule];
        newSchedule = newSchedule.map((schedule) => {
          return {
            ...schedule,
            duration,
          };
        });

        rec = [...recurringDates];
        if (
          onBoarding.onboarding.learningPackage &&
          onBoarding.onboarding.learningPackage.numberOfLessons
        ) {
          let noOfWeeks =
            onBoarding.onboarding.learningPackage.numberOfLessons /
              lessonFrequency -
            1;
          let noOfWeeksRem =
            onBoarding.onboarding.learningPackage.numberOfLessons %
            lessonFrequency;
          newSchedule.forEach((schedule) => {
            if (
              onBoarding.onboarding!.learningPackage!.type.toLowerCase() ===
              "monthly package"
            ) {
              for (let i = 0; i <= 3; i++) {
                const newRecurringDate = {
                  date: incrementDateWithSameLocalTime(
                    schedule.date || new Date(),
                    i,
                    schedule.timeZone || ""
                  ),
                  duration: schedule.duration,
                  default: false,
                  time: schedule.date,
                  timeZone: schedule.timeZone,
                  utc: incrementDateWithSameLocalTime(
                    schedule.utc || new Date(),
                    i,
                    schedule.timeZone || ""
                  ),
                  fullLessonDate: incrementDateWithSameLocalTime(
                    schedule.utc || new Date(),
                    i,
                    schedule.timeZone || ""
                  ),
                };
                setRecurringDates((prevState) => [
                  ...prevState,
                  newRecurringDate,
                ]);
                rec = [...rec, newRecurringDate];
              }
            } else {
              let remWeek = 0;
              for (let i = 0; i <= noOfWeeks; i++) {
                const newRecurringDate = {
                  date: incrementDateWithSameLocalTime(
                    schedule.date || new Date(),
                    i,
                    schedule.timeZone || ""
                  ),
                  duration: schedule.duration,
                  default: false,
                  time: schedule.date,
                  timeZone: schedule.timeZone,
                  utc: incrementDateWithSameLocalTime(
                    schedule.utc || new Date(),
                    i,
                    schedule.timeZone || ""
                  ),
                  fullLessonDate: incrementDateWithSameLocalTime(
                    schedule.utc || new Date(),
                    i,
                    schedule.timeZone || ""
                  ),
                };
                setRecurringDates((prevState) => [
                  ...prevState,
                  newRecurringDate,
                ]);
                rec = [...rec, newRecurringDate];
                remWeek = i + 1;
              }
              if (noOfWeeksRem > 0) {
                const newRecurringDate = {
                  date: incrementDateWithSameLocalTime(
                    schedule.date || new Date(),
                    remWeek,
                    schedule.timeZone || ""
                  ),
                  duration: schedule.duration,
                  default: false,
                  time: schedule.date,
                  timeZone: schedule.timeZone,
                  utc: incrementDateWithSameLocalTime(
                    schedule.utc || new Date(),
                    remWeek,
                    schedule.timeZone || ""
                  ),
                  fullLessonDate: incrementDateWithSameLocalTime(
                    schedule.utc || new Date(),
                    remWeek,
                    schedule.timeZone || ""
                  ),
                };
                setRecurringDates((prevState) => [
                  ...prevState,
                  newRecurringDate,
                ]);
                rec = [...rec, newRecurringDate];
                noOfWeeksRem -= 1;
              }
            }
          });
        }
      }

      try {
        const sub = await PaymentApi.getSubscription({
          userId: onBoarding.onboarding.userId,
          status: "ACTIVE",
        });
        await bookLessons(sub?.data[0]?._id, onBoarding, rec);
        await PaymentApi.updateSubscription({
          subscriptionId: sub?.data[0]?._id,
          lessonSchedule: newSchedule,
        });
        navigate(routes.DIRECT_TO_PAY_WELCOME_B.url + "/lesson-confirmation");
      } catch (error) {
        console.log("caught error: ", error);
        toast.error("Error booking lessons", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return;
      }
    } else {
      document
        ?.getElementById("availability")
        ?.scrollIntoView({ behavior: "smooth", block: "start" });

      toast.error(
        "Please schedule a time for your lesson in the availability section.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      return;
    }
  };

  return (
    <div
      className="white-card-rounded px-3 py-3 max-w-[330px] md:max-w-[340px]"
      id="video-booking-card"
    >
      <ReactPlayer
        url={
          tutor.videoShowcaseUrl ||
          `${process.env.REACT_APP_ASSET_CDN}/DirectYorubaVideo.mp4`
        }
        controls={true}
        width="320px"
        height="200px"
        style={{
          borderRadius: "30px",
        }}
      />
      {/* {!tutor.videoShowcaseUrl && (
        <img
          src={tutor.avatar}
          alt={tutor.firstname}
          className="rounded-xl w-full h-[14rem]"
        />
      )} */}
      <div className="pb-4 mt-4">
        {/* Rating & Price */}

        <div className="pb-4">
          <p className="text-primary text-center text-lg font-semibold">
            About the lesson
          </p>
        </div>
        <div className="mt-3">
          <div className="flex gap-2 mb-2 items-center px-3">
            <p className="text-md w-28 text-primary font-normal">Subject:</p>

            <p className="text-md text-secondary font-semibold capitalize">
              {subject
                ? subject
                : onBoarding.onboarding?.subject
                ? onBoarding.onboarding?.subject
                : ""}
            </p>
          </div>
          {Array(Number(lessonFrequency))
            .fill("")
            .map((freq, index) => (
              <div
                key={index}
                className={`flex gap-2 mb-2 items-center py-3 relative hover:bg-pale-pink-bg cursor-pointer rounded-md px-3 ${
                  index === selectedLessonScheduleId &&
                  "ring-2  ring-secondary bg-pale-pink-bg "
                } `}
                id={`${addSuffix(index + 1)}-lesson`}
                onClick={() => {
                  setSelectedLessonScheduleId(index);
                  document
                    ?.getElementById("availability")
                    ?.scrollIntoView({ behavior: "smooth", block: "start" });
                }}
              >
                {index === selectedLessonScheduleId &&
                  !lessonSchedule[selectedLessonScheduleId] && (
                    <div className="absolute left-0 right-0 top-0 bottom-0 ring-2 ring-secondary animate-mini-ping-sm sm:animate-mini-ping-lg rounded-md opacity-75"></div>
                  )}
                <p className={`text-md w-28 text-primary font-normal `}>
                  {lessonFrequency > 1
                    ? addSuffix(index + 1) + " Lesson:"
                    : "Lesson:"}
                </p>

                <p className="text-md text-secondary font-semibold">
                  {lessonSchedule[index]?.date &&
                    formatDateToNeutral(
                      lessonSchedule[index]?.date,
                      lessonSchedule[index]?.time
                    )}
                </p>
              </div>
            ))}
        </div>
        <button
          className="purple-button-no-shadow w-full mt-4 flex items-center justify-center uppercase"
          onClick={handleBookLessonClick}
          disabled={isLoading}
          data-test="d2pBookALessonButton"
        >
          {isLoading && <div className="loader"></div>}
          Book A Lesson
        </button>
      </div>
    </div>
  );
}
