import { NavLink } from "react-router-dom";

export default function PaymentMade() {
  return (
    <div className="mx-auto xl:w-1/2 lg:w-3/4 w-full text-center mt-10">
      <h1 className="leading-relaxed text-6xl font-extrabold">Thank you</h1>
      <span className="inline-block h-1 w-10 rounded bg-primary-medium mt-8 mb-6"></span>
      <p className="text-lg">
        Thank you for your payment. This payment link has already been used, and
        your payment was successfully processed. If you have any further
        questions or need assistance, please don't hesitate to reach out to our
        <a href="mailto:info@topset.app" className="underline">
          {" "}
          support team.
        </a>
      </p>
      <NavLink to="/">
        <button className="mt-6 cursor-pointer bg-secondary border-secondary border-2 text-white py-3 px-4 rounded-lg hover:bg-white hover:text-secondary hover:border-2 hover:py-3 hover:px-4 hover:border-secondary">
          Back to home
        </button>
      </NavLink>
    </div>
  );
}
