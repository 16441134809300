import { ca } from "date-fns/locale";

export const initialState = {
  user: null,
  userCowryPouch : null,
  selectedLesson: null,
  lessonRequests: null,
  notifications: null,
  unreadNotifications: null,
  userNewRewards: null,
  userLessonProgress: null,
  userLessonDetails: null,
  unreadMessages: null,
  learningAppLoading: false,
  showMobileMenu: false,
  globalGroupLessons: null,
  groupLessonAgeGroup: [],
  groupLessonFilterTag: [],
  tutorCurrency: null,
  selectedLessonLevels: null,
  isComponentLoading: false,
  fiftyOff1stLesson: false,
  isLessonPriceSaved: true,
  tutorAvailability: null,
  tutorTeachingDemo: null,
  tutorZoomDetails: {
    zoomMeetingID: "",
    zoomPasscode: "",
    zoomMeetingLink: "",
  },
  clientIpCountry: null,
  allTutors: null,
  tutorInterviewSubject: null,
  tutorFilterPrice: [0, 50000],
  tutorFilterRating: 0,
  tutorFilterSubject: [],
  tutorFilterLocation: [],
  filterButtonStatus: [false, false, false, false],
  levelButtonStatus: [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ],
  tutorPeriodSelectionValues: [],
  tutorPeriodSelectionsTable: [
    [false, false, false, false],
    [false, false, false, false],
    [false, false, false, false],
    [false, false, false, false],
    [false, false, false, false],
    [false, false, false, false],
    [false, false, false, false],
  ],
  adSource: null,
  subjectOptions: [],
  languageOptions: [],
  lessonFeedbacks: null,
  unreadFeedbacks: null,
  ipInfo: null,
  upcomingLessons: null,
  pastLessons: null,
  activeSubscription: null,
  pendingSubscriptions: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGOUT_USER":
      window.localStorage.removeItem("accessToken");
      return {
        user: null,
      };
    case "SET_ALL_TUTORS":
      return {
        ...state,
        allTutors: action.allTutors,
      };
    case "SET_LEARNING_APP_LOADING":
      return {
        ...state,
        learningAppLoading: action.learningAppLoading,
      };
    case "SET_CLIENT_COUNTRY":
      return {
        ...state,
        clientIpCountry: action.clientIpCountry,
      };
    case "SET_COMPONENT_LOADING_STATUS":
      return {
        ...state,
        isComponentLoading: action.isComponentLoading,
      };
    case "SET_FILTER_BUTTON_STATUS":
      return {
        ...state,
        filterButtonStatus: action.filterButtonStatus,
      };
    case "SET_GROUP_LESSONS":
      return {
        ...state,
        globalGroupLessons: action.globalGroupLessons,
      };
    case "SET_GROUP_LESSON_AGE_GROUP":
      return {
        ...state,
        groupLessonAgeGroup: action.groupLessonAgeGroup,
      };
    case "SET_GROUP_LESSON_FILTER_TAG":
      return {
        ...state,
        groupLessonFilterTag: action.groupLessonFilterTag,
      };
    case "SET_SHOW_MOBILE_MENU":
      return {
        ...state,
        showMobileMenu: action.showMobileMenu,
      };
    case "SET_LESSON_REQUESTS":
      return {
        ...state,
        lessonRequests: action.lessonRequests,
      };
    case "SET_LESSON_FEEDBACKS":
      return {
        ...state,
        lessonFeedbacks: action.lessonFeedbacks,
      };
    case "SET_USER_UNREAD_FEEDBACKS":
      return {
        ...state,
        unreadFeedbacks: action.unreadFeedbacks,
      };
      case "SET_USER_COWRY_POUCH":
      return {
        ...state,
        userCowryPouch: action.userCowryPouch,
      };
    case "SET_LESSON_PRICE_SAVE_STATUS":
      return {
        ...state,
        isLessonPriceSaved: action.isLessonPriceSaved,
      };
    case "SET_LEVEL_BUTTON_STATUS":
      return {
        ...state,
        levelButtonStatus: action.levelButtonStatus,
      };
    case "SET_SELECTED_LESSON":
      return {
        ...state,
        selectedLesson: action.selectedLesson,
      };
    case "SET_SELECTED_LESSON_LEVELS":
      return {
        ...state,
        selectedLessonLevels: action.selectedLessonLevels,
      };
    case "SET_FIFTY_PERCENT_OFF":
      return {
        ...state,
        fiftyOff1stLesson: action.fiftyOff1stLesson,
      };
    case "SET_TUTOR_AVAILABILITY":
      return {
        ...state,
        tutorAvailability: action.tutorAvailability,
      };
    case "SET_TUTOR_CURRENCY":
      return {
        ...state,
        tutorCurrency: action.tutorCurrency,
      };
    case "SET_TUTOR_FILTER_LOCATION":
      return {
        ...state,
        tutorFilterLocation: action.tutorFilterLocation,
      };
    case "SET_TUTOR_FILTER_PRICE":
      return {
        ...state,
        tutorFilterPrice: action.tutorFilterPrice,
      };
    case "SET_TUTOR_FILTER_RATING":
      return {
        ...state,
        tutorFilterRating: action.tutorFilterRating,
      };
    case "SET_TUTOR_FILTER_SUBJECT":
      return {
        ...state,
        tutorFilterSubject: action.tutorFilterSubject,
      };
    case "SET_TUTOR_PERIOD_SELECTIONS_TABLE":
      return {
        ...state,
        tutorPeriodSelectionsTable: action.tutorPeriodSelectionsTable,
      };
    case "SET_TUTOR_PERIOD_SELECTION_VALUES":
      return {
        ...state,
        tutorPeriodSelectionValues: action.tutorPeriodSelectionValues,
      };
    case "SET_TUTOR_TEACHING_DEMO":
      return {
        ...state,
        tutorTeachingDemo: action.tutorTeachingDemo,
      };
    case "SET_TUTOR_ZOOM_DETAILS":
      return {
        ...state,
        tutorZoomDetails: action.tutorZoomDetails,
      };
    case "SET_USER":
      return {
        ...state,
        user: action.user,
      };
    case "SET_USER_UNREAD_MESSAGES":
      return {
        ...state,
        unreadMessages: action.unreadMessages,
      };
    case "SET_USER_UNREAD_NOTIFICATIONS":
      return {
        ...state,
        unreadNotifications: action.unreadNotifications,
      };
    case "SET_USER_NEW_REWARDS":
      return {
        ...state,
        userNewRewards: action.userNewRewards,
      };
    case "SET_USER_LESSON_PROGRESS":
      return {
        ...state,
        userLessonProgress: action.userLessonProgress,
      };
    case "SET_USER_LESSON_DETAILS":
      return {
        ...state,
        userLessonDetails: action.userLessonDetails,
      };
    case "SET_TUTOR_INTERVIEW_SUBJECT":
      return {
        ...state,
        tutorInterviewSubject: action.tutorInterviewSubject,
      };
    case "SET_USER_NOTIFICATIONS":
      return {
        ...state,
        notifications: action.notifications,
      };
    case "SET_AD_SOURCE":
      return {
        ...state,
        adSource: action.adSource,
      };
    case "SET_SUBJECTS":
      return {
        ...state,
        subjectOptions: action.subjectOptions,
      };
    case "SET_LANGUAGES":
      return {
        ...state,
        languageOptions: action.languageOptions,
      };
    case "SET_CLIENT_IP_INFO":
      return {
        ...state,
        ipInfo: action.ipInfo,
      };
    case "SET_UPCOMING_LESSONS":
      return {
        ...state,
        upcomingLessons: action.upcomingLessons,
      };
    case "SET_PAST_LESSONS":
      return {
        ...state,
        pastLessons: action.pastLessons,
      };
    case "SET_ACTIVE_SUBSCRIPTION":
      return {
        ...state,
        activeSubscription: action.activeSubscription,
      };
    case "SET_PENDING_SUBSCRIPTIONS":
      return {
        ...state,
        pendingSubscriptions: action.pendingSubscriptions,
      };

    default:
      return state;
  }
};

export default reducer;
