import { ITestimonials } from "src/components/DirectToPay/VideoPlayer";

export const yorubaTestimonials : ITestimonials[] = [
    {
      name: "Bibianna's experience",
      videoUrl: `${process.env.REACT_APP_ASSET_CDN}/Yoruba%20-%20Bibianna%20Fasogbon..mp4`,
      thumbnail: `${process.env.REACT_APP_ASSET_CDN}/bibiana_thumbnail.png`
    },
    {
      name: "Daniel's experience",
      videoUrl: `${process.env.REACT_APP_ASSET_CDN}/Yoruba%20-%20Daniel%20lopez.mp4`,
      thumbnail: `${process.env.REACT_APP_ASSET_CDN}/lopez_thumbnail.png`
    },
    {
      name: "Emmanuel's experience",
      videoUrl: `${process.env.REACT_APP_ASSET_CDN}/Yoruba%20-%20Emmanuel%20Ajayi.mp4`,
      thumbnail: `${process.env.REACT_APP_ASSET_CDN}/eajayi_thumbnail.png`
    },
    {
      name: "Jeanette's experience",
      videoUrl: `${process.env.REACT_APP_ASSET_CDN}/Yoruba%20-%20Jeanette%20Adereti.mp4`,
      thumbnail: `${process.env.REACT_APP_ASSET_CDN}/jeanette_thumbnail.png`
    },
    {
      name: "Okunlola's experience",
      videoUrl: `${process.env.REACT_APP_ASSET_CDN}/Yoruba%20-%20Okunlola%20Odusote.mp4`,
      thumbnail: `${process.env.REACT_APP_ASSET_CDN}/odusote_thumbnail.png`
    },
    {
      name: "Yemi's experience",
      videoUrl: `${process.env.REACT_APP_ASSET_CDN}/Yoruba%20-%20Yemi%20Fatunbi.MP4`,
      thumbnail: `${process.env.REACT_APP_ASSET_CDN}/yfatunbi_thumbnail.png`
    }
  ];
  


  export const igboTestimonials : ITestimonials[] = [
     
    {
      name: "Ebuka's experience",
      videoUrl: `${process.env.REACT_APP_ASSET_CDN}/Igbo%20-%20Ebuka%20Uzodike.mp4`,
      thumbnail: `${process.env.REACT_APP_ASSET_CDN}/ebuka_thumbnail.png`
    },
    {
      name: "Chinedu's experience",
      videoUrl: `${process.env.REACT_APP_ASSET_CDN}/Igbo%20-%20Chinedu%20Emenuga%202.mp4`,
      thumbnail: `${process.env.REACT_APP_ASSET_CDN}/chinedu2_thumbnail.png`
    },
    {
      name: "Kamsi's experience",
      videoUrl: `${process.env.REACT_APP_ASSET_CDN}/Igbo%20-%20Kamsi%20Asiegbu.mp4`,
      thumbnail: `${process.env.REACT_APP_ASSET_CDN}/kamsi_thumbnail.png`
    },
    {
      name: "Marlene's experience",
      videoUrl: `${process.env.REACT_APP_ASSET_CDN}/Igbo%20-%20Marlene%20(Chioma)%20Ehirim.mp4`,
      thumbnail: `${process.env.REACT_APP_ASSET_CDN}/marlene_thumbnail.png`
    },
    {
      name: "Mrs Elizabeth and Nelson's experience",
      videoUrl: `${process.env.REACT_APP_ASSET_CDN}/Igbo%20-%20Mrs%20Elizabeth%20Izuora%20and%20Nelson%201.mp4`,
      thumbnail: `${process.env.REACT_APP_ASSET_CDN}/eliz_nelson_thumbnail.png`
    }
  ];
  