import axios from "axios";
import {
  ICreateSubscription,
  IUpdateOrder,
  IUpdateSubscription,
} from "src/interfaces/payment";

export async function createGroupLessonOrder(
  user: any,
  groupLessonId: string,
  lessonPrice: { value: number; currencyCode: string }
): Promise<any | undefined[]> {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/payment/create-group-lesson-order`,
      { user, groupLessonId, lessonPrice }
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function chargeSavedPaymentMethodForGroupLesson(
  user: any,
  groupLessonId: string,
  lessonPrice: { value: number; currencyCode: string }
): Promise<any | undefined[]> {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/payment/charge-saved-payment-method-group-lesson`,
      { user, groupLessonId, lessonPrice }
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function chargeSavedPaymentMethodForOneOnOneLesson(
  user: any,
  bookingDetails: any
): Promise<any | undefined[]> {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/payment/charge-saved-payment-method-one-on-one-lesson`,
      { user, bookingDetails }
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function sendPaymentConfirmation(
  user: any,
  revolutPublicId: string
): Promise<any | undefined[]> {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/payment/confirm-group-lesson-order`,
      { user, revolutPublicId }
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function createChargeVerification(
  user: any
): Promise<any | undefined[]> {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/payment/create-charge-verification`,
      { user }
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function savePaymentMethodToUserAccount(
  user: any
): Promise<any | undefined[]> {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/payment/save-user-payment-method`,
      { user }
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function refundChargeVerification(
  orderId: string
): Promise<any | undefined[]> {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/payment/refund-charge-verification`,
      { orderId }
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function createOneOnOneLessonOrder(
  user: any,
  bookingDetails: any
): Promise<any | undefined[]> {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/payment/create-one-on-one-order`,
      { user, bookingDetails }
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}
export async function createRevolutOrder(
  data: any
): Promise<any | undefined[]> {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_V2_SERVER_URL}/payment/initiate/`,
      data
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}
export async function getPaymentOrder(data: any): Promise<any | undefined[]> {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_V2_SERVER_URL}/payment`,
      {
        params: data,
      }
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}
export async function updateRevolutOrder(
  body: IUpdateOrder
): Promise<any | undefined[]> {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_V2_SERVER_URL}/payment/update`,
      body
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}
export async function createSubscription(
  body: ICreateSubscription
): Promise<any | undefined[]> {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_V2_SERVER_URL}/subscription`,
      body
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}
export async function updateSubscription(
  body: IUpdateSubscription
): Promise<any | undefined[]> {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_V2_SERVER_URL}/subscription`,
      body
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}
export async function getSubscription(data: any): Promise<any | undefined[]> {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_V2_SERVER_URL}/subscription`,
      {
        params: data,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function getLearningPackages(): Promise<any | undefined[]> {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_V2_SERVER_URL}/utility/learning-package`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
}
export async function postApplyDiscountCode(
  code: string,
  subscriptionId: string
): Promise<any> {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_V2_SERVER_URL}/payment/apply-discount-code`,
      { code, subscriptionId }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
}
