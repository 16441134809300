import clsx from "clsx";
import { useState } from "react";
import FixedHeaderWithoutNav from "src/components/Shared/FixedHeaderWithoutNav";
import { routes } from "src/Routes";
import languages from "src/utils/helper/languages";
export default function ChooseLanguage() {
  const [language, setLanguage] = useState("");
  return (
    <div className="h-screen flex flex-col text-primary">
      <FixedHeaderWithoutNav />
      <div className="flex-grow flex flex-col justify-center items-center p-4">
        <h1 className="text-xl font-semibold">
          Choose the language you want to learn
        </h1>
        <form
          action={`${routes.DIRECT_TO_PAY_WELCOME.url}`}
          className="w-full max-w-md space-y-4 mt-4"
        >
          {languages.map((lang, index) => (
            <div key={index}>
              <input
                type="checkbox"
                id={lang}
                name="lang"
                value={lang}
                hidden
                onChange={(e) => setLanguage(e.target.value)}
                checked={language === lang}
              />
              <label
                htmlFor={lang}
                className={`w-full cursor-pointer  border p-2 sm:p-4 rounded-md flex justify-center items-center gap-2 sm:gap-5 font-medium ${
                  language === lang && "bg-primary-pale border-primary"
                }`}
              >
                {lang.toUpperCase()}
              </label>
            </div>
          ))}
          <br />
          <button
            className={clsx({
              "mx-auto bg-primary hover:bg-primary-light text-white p-2 sm:px-4 sm:py-4 rounded-md flex flex-wrap justify-center min-w-full sm:gap-5 gap-2 items-center font-medium":
                language,
              "bg-gray-200 text-white  mx-auto p-2 sm:px-4 sm:py-4 rounded-md flex flex-wrap justify-center min-w-full sm:gap-5 gap-2 items-center font-medium":
                !language,
            })}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}
