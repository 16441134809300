import CircularProgress from "src/components/Shared/CircularProgress";

interface ICardInfo {
  setIframeLoading: (val: boolean) => void;
  handleRevolutPopup: () => void;
  iframeLoading: boolean;
}
export default function CardInfo({
  setIframeLoading,
  handleRevolutPopup,
  iframeLoading,
}: ICardInfo) {
  return (
    <div className="white-card-rounded font-semibold p-4 cursor-pointer sm:self-start flex-1">
      <p>Choose a payment method</p>
      <div className="flex items-center mt-4">
        <div
          className="bg-pale-pink-bg rounded-full px-6 py-2 text-secondary "
          onClick={() => {
            setIframeLoading(true);
            handleRevolutPopup();
          }}
          data-test="d2pRevolutCardButton"
        >
          {iframeLoading ? <CircularProgress /> : <span>Card</span>}
        </div>
        <div id="revolut-payment-request"></div>
      </div>
    </div>
  );
}
