import { lazy, Suspense, useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { GuestGuard } from "src/components/Guards/GuestGuard";
import { routes } from "src/Routes";
import { NewTutorGuard } from "./components/Guards/NewTutorGuard";
import {
  getClientIpCountry,
  getLanguages,
  getSubjects,
} from "./services/client";

import { Helmet } from "react-helmet";
import LoadingScreen from "src/components/LottieAnimations/LearningAppLoader";
import SuspenseLoader from "src/components/LottieAnimations/SpinningRose";
import { useStateValue } from "src/context/StateProvider";
import * as CookieConsent from "vanilla-cookieconsent";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import { NonStudentGuard } from "./components/Guards/NonStudentGuard";
import LessonPrice from "./components/LessonPrice";

import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import { NonTutorGuard } from "./components/Guards/NonTutorGuard";
import TeacherReferralDashboardLayout from "./layouts/TeacherReferralDashboard";

import { NonTeacherReferrerGuard } from "./components/Guards/NonTeacherReferrerGuard";
import LanguageLanding from "./views/LanguageLanding";

import PaymentConfirmation from "./components/Checkout/PaymentConfirmation";
import { AuthGuard } from "./components/Guards/AuthGuard";
import ChatBubble from "./components/Shared/ChatBubble";
import GoogleCallback from "./components/Shared/googleCallbackHandler";
import WhatsAppLink from "./components/WhatsappLink";
import LoginForm from "./views/Authentication/Login";
import StudentEmailSignup from "./views/Authentication/Signup/Student/StudentEmailSignup";
import StripePaymentForm from "./views/BookAGroupLesson/PaymentModal/StripePaymentForm";
import Checkout from "./views/Checkout";
import ChooseLanguage from "./views/ChooseLanguage";
import DirectToPayWelcome from "./views/DirectToPay/CustomerInfo";
import DirectToPayMeetTutors from "./views/DirectToPay/MeetTutors";
import ViewTutorProfile from "./views/DirectToPay/ViewTutorPage";
import DirectToPayAlternatePaymentPlans from "./views/DirectToPayAlternate/PaymentPlans";
import DirectToPayAlternate from "./views/DirectToPayAlternate/Welcome";
import DirectToPayAlternateGetStarted from "./views/DirectToPayAlternate/Welcome/GetStarted";
import LearningApp from "./views/MiniGames/LearningApp/App/LearningApp";
import SentenceBuilder from "./views/MiniGames/LearningApp/App/SentenceBuilder/SentenceBuilder";
import LanguageLevelLanding from "./views/MiniGames/LearningApp/Landing/LanguageLevelLanding";
import LanguageLanguageLanding from "./views/MiniGames/LearningApp/Landing/LearnLanguageLanding";
import ScratchGames from "./views/MiniGames/ScratchGames";
import GamePage from "./views/MiniGames/ScratchGames/GamePage";
import WordGenerator from "./views/MiniGames/WordGenerator";
import Leaderboard from "./views/StudentDashboard/Leaderboard";
import Rewards from "./views/StudentDashboard/Rewards";
import SessionDetails from "./views/StudentDashboard/Learn/SessionDetails";
import TopicDetails from "./views/StudentDashboard/Learn/TopicDetails";
import StudentPayment from "./views/StudentDashboard/StudentProfile/Payment";
import LessonRewards from "./views/TutorDashboard/LessonRewards";
import VirtualImmersions from "./views/VirtualImmersions";
import NewCalendarView from "./views/NewCalendarView";
import SentenceContructionSelection from "./components/StudentDashboard/Learn/SentenceConstruction/SentenceContructionSelection";
import SentenceConstructionSession from "./components/StudentDashboard/Learn/SentenceConstruction/SentenceConstructionSession";

const StudentProfile = lazy(
  () => import("./views/TutorDashboard/TutorStudents/StudentProfile")
);
const StudentLessonFeedback = lazy(
  () => import("./views/StudentDashboard/StudentLessonFeedback")
);

const ReferralHome = lazy(
  () =>
    import(
      "./views/TeacherReferralProgram/TeacherReferralDashboard/ReferralHome"
    )
);

const ProfileSettings = lazy(
  () =>
    import(
      "./views/TeacherReferralProgram/TeacherReferralDashboard/TeacherProfile/ProfileSettings"
    )
);

const TeacherBio = lazy(
  () =>
    import(
      "./views/TeacherReferralProgram/TeacherReferralDashboard/TeacherProfile"
    )
);

const StudentDashboardFeedback = lazy(
  () => import("./views/StudentDashboard/StudentLessons/Feedback")
);
const StudentAllFeedback = lazy(
  () =>
    import(
      "./views/StudentDashboard/StudentLessons/Feedback/StudentAllFeedback"
    )
);
const TutorFeedback = lazy(
  () => import("./views/TutorDashboard/TutorLessons/Feedback")
);
const StudentNotes = lazy(
  () => import("./views/TutorDashboard/TutorLessons/StudentNotes")
);
const AllTutorFeedback = lazy(
  () => import("./views/TutorDashboard/TutorLessons/Feedback/AllTutorFeedback")
);

const Home = lazy(() => import("./views/Home"));
const Chemistry = lazy(() => import("./views/Services/Chemistry"));
const TutorSignup = lazy(
  () => import("./views/Authentication/Signup/Tutor/TutorSignup")
);
const TutorEmailSignup = lazy(
  () => import("./views/Authentication/Signup/Tutor/TutorEmailSignup")
);
const Auth = lazy(() => import("./components/Shared/Auth"));
const OnboardingStep0 = lazy(
  () => import("./views/TutorDashboard/NewOnboarding/OnboardingStep0")
);
const OnboardingStep1 = lazy(
  () => import("./views/TutorDashboard/NewOnboarding/OnboardingStep1")
);
const OnboardingStep05 = lazy(
  () => import("./views/TutorDashboard/NewOnboarding/OnboardingStep05")
);
const TutorDashboardLayout = lazy(() => import("./layouts/TutorDashboard"));
const TutorBio = lazy(() => import("./views/TutorDashboard/TutorProfile/Bio"));
const TutorOnboarding = lazy(
  () => import("./views/TutorDashboard/TutorProfile/TutorOnboarding")
);
const TutorSettings = lazy(
  () => import("./views/TutorDashboard/TutorProfile/TutorSettings")
);
const TutorOverview = lazy(
  () => import("./views/TutorDashboard/TutorOverview")
);
const StudentReferral = lazy(
  () => import("./views/StudentDashboard/StudentProfile/Referral")
);
const TutorLessons = lazy(
  () => import("./views/TutorDashboard/TutorLessons/Manage")
);
const TutorStudents = lazy(
  () => import("./views/TutorDashboard/TutorStudents")
);
const TutorAvailability = lazy(
  () => import("./views/TutorDashboard/TutorAvailability")
);
const Error404 = lazy(() => import("./components/Shared/Error404"));
const BecomeATutor = lazy(() => import("./views/BecomeATutor"));
const NextSteps = lazy(() => import("./views/BecomeATutor/NextSteps"));
const FindATutor = lazy(() => import("./views/FindATutor"));
const OuterPagesLayout = lazy(() => import("./layouts/OuterPages"));
const ViewTutorPage = lazy(() => import("./views/ViewTutorPage"));

const StudentSignup = lazy(
  () => import("./views/Authentication/Signup/Student/StudentSignup")
);

const BookALesson = lazy(() => import("./views/BookALesson"));
const StudentDashboardLayout = lazy(() => import("./layouts/StudentDashboard"));
const StudentSettings = lazy(
  () => import("./views/StudentDashboard/StudentProfile/StudentSettings")
);
const AcademicInfo = lazy(
  () => import("./views/StudentDashboard/StudentProfile/AcademicInfo")
);
const StudentBio = lazy(
  () => import("./views/StudentDashboard/StudentProfile/Bio")
);
const TutorLessonRequests = lazy(
  () => import("./views/TutorDashboard/TutorLessons/Requests")
);
const FindGroupLesson = lazy(() => import("./views/FindGroupLesson"));
const ViewGroupLessonPage = lazy(() => import("./views/ViewGroupLessonPage"));
const BookAGroupLesson = lazy(() => import("./views/BookAGroupLesson"));

const Messenger = lazy(() => import("./views/Messenger"));
const Notifications = lazy(() => import("./views/Notifications"));
const ForgotPassword = lazy(
  () => import("./views/Authentication/ForgotPassword")
);
const UpdatePassword = lazy(
  () => import("./views/Authentication/UpdatePassword")
);

const StudentLessonRequestsToAdmin = lazy(
  () => import("./views/StudentDashboard/StudentLessons/LessonRequestsToAdmin")
);
const StudentResources = lazy(() => import("./views/StudentDashboard/Learn"));

const StudentUpcomingLessonsViaAdmin = lazy(
  () =>
    import("./views/StudentDashboard/StudentLessons/UpcomingLessonsViaAdmin")
);
const TutorUpcomingLessonsViaAdmin = lazy(
  () => import("./views/TutorDashboard/TutorLessons/UpcomingLessonsViaAdmin")
);
const ReportNoShow = lazy(
  () => import("./views/TutorDashboard/ReportNoShow/index")
);
const YorubaLanding = lazy(() => import("./views/Home/YorubaLanding"));
const About = lazy(() => import("./views/About"));
const BookALessonViaAdminNoAuth = lazy(
  () => import("./views/BookALessonViaAdminnoAuth")
);
const Contact = lazy(() => import("./views/Contact"));
const Terms = lazy(() => import("./views/Terms"));
const YorubaRoadmap = lazy(() => import("./views/Roadmap/Yoruba"));
const HausaRoadmap = lazy(() => import("./views/Roadmap/Hausa"));
const IgboRoadmap = lazy(() => import("./views/Roadmap/Igbo"));
const CreatePassword = lazy(
  () => import("./views/Authentication/CreatePassword")
);
const VerifyEmail = lazy(() => import("./views/Authentication/VerifyEmail"));
const StudentHomework = lazy(
  () => import("./views/StudentDashboard/StudentHomework")
);
const TutorHomework = lazy(
  () => import("./views/TutorDashboard/TutorHomework")
);
const PrivacyPolicy = lazy(() => import("./views/Policies/PrivacyPolicy"));
const CookiePolicy = lazy(() => import("./views/Policies/CookiePolicy"));
const TutorLessonHistoryViaAdmin = lazy(
  () => import("./views/TutorDashboard/TutorLessons/LessonHistoryViaAdmin")
);
const StudentLessonHistoryViaAdmin = lazy(
  () => import("./views/StudentDashboard/StudentLessons/LessonHistoryViaAdmin")
);
const UserImpersonation = lazy(
  () => import("./views/Authentication/UserImpersonation/UserImpersonation")
);
const OnboardingExercise = lazy(
  () => import("./views/TutorDashboard/shared/OnboardingExercise")
);
const PaymentInfo = lazy(
  () =>
    import("./views/TutorDashboard/TutorProfile/PaymentInfo/PaymentInfo.tsx")
);
const Services = lazy(() => import("./views/Services"));
const Faqs = lazy(() => import("./views/Faqs"));
const Languages = lazy(() => import("./views/Languages"));
const TeacherReferralSignup = lazy(
  () => import("./views/TeacherReferralProgram/TeacherReferralSignup")
);
const ThankYou = lazy(() => import("./components/BookALesson/ThankYou"));
const CheckoutThankYou = lazy(() => import("./components/Checkout/ThankYou"));

if (process.env.REACT_APP_GTM_ID) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
  };
  TagManager.initialize(tagManagerArgs);
}

if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
}

function App() {
  const [{ learningAppLoading }, dispatch] = useStateValue();

  useEffect(() => {
    const getCountry = async () => {
      const { countryCode: ipCountryCode, ipInfo } = await getClientIpCountry();
      const subjectOptions = await getSubjects();
      let languageOptions = await getLanguages();
      try {
        languageOptions?.filter((lang: any) => lang.value !== "Hausa");
      } catch (error) {
        console.error(error);
      }
      await dispatch({
        type: "SET_CLIENT_COUNTRY",
        clientIpCountry: ipCountryCode,
      });
      await dispatch({
        type: "SET_CLIENT_IP_INFO",
        ipInfo: ipInfo,
      });
      await dispatch({
        type: "SET_SUBJECTS",
        subjectOptions,
      });
      await dispatch({
        type: "SET_LANGUAGES",
        languageOptions,
      });
    };
    getCountry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /**
     * All config. options available here:
     * https://cookieconsent.orestbida.com/reference/configuration-reference.html
     */
    CookieConsent.run({
      categories: {
        necessary: {
          enabled: true, // this category is enabled by default
          readOnly: true, // this category cannot be disabled
        },
        analytics: {},
      },

      language: {
        default: "en",
        translations: {
          en: {
            consentModal: {
              title: "We use cookies!",
              description: `
              We use cookies to improve your experience on our website. By clicking 'Accept All Cookies,' you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts. You can manage your cookie settings by clicking 'Cookie Settings.' For more information, please review our <a href="/cookie-policy" target="_blank">Cookie Policy</a>.
            `,
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Reject all",
              showPreferencesBtn: "Cookie Settings",
            },
            preferencesModal: {
              title: "Manage cookie preferences",
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Reject all",
              savePreferencesBtn: "Accept current selection",
              closeIconLabel: "Close modal",
              sections: [
                {
                  title: "You can customize your cookie preferences below",
                  description:
                    "We value your privacy and want to ensure you have control over how your data is used. You can customize your cookie preferences below",
                },
                {
                  title: "Strictly Necessary cookies",
                  description:
                    "These cookies are essential for the proper functioning of the website and cannot be disabled.",
                  //this field will generate a toggle linked to the 'necessary' category
                  linkedCategory: "necessary",
                },
                {
                  title: "Performance and Analytics",
                  description:
                    "These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.",
                  linkedCategory: "analytics",
                },
                {
                  title: "More information",
                  description:
                    'For any queries in relation to my policy on cookies and your choices, please <a href="/contact">contact us</a>',
                },
              ],
            },
          },
        },
      },
    });
  }, []);

  if (learningAppLoading) {
    return (
      <div className="bg-[#fbfbfc] h-screen flex justify-center items-center">
        <div className="w-[500px]">
          <LoadingScreen size="100" />
        </div>
      </div>
    );
  }

  return (
    <Router>
      <Suspense fallback={<SuspenseLoader />}>
        <Auth>
          <Helmet>
            <title>TopSet</title>
            <meta
              name="description"
              content="Speak your native language with pride. Learn Yoruba, Igbo. Book a tutor now!"
            />
            <meta
              name="keywords"
              content="Speak your native language with pride. Learn Yoruba, Igbo. Book a tutor now!"
            />
            <script>
              {`(function(w,d,s,l,i){
      w[l]=w[l]||[];
      w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0], j=d.createElement(s), dl=l!='dataLayer'?'&l='+l:'';
      j.async=true; j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
      f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-PF6VZKX');`}
            </script>
            <script type="text/javascript">
              {`(function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){ (c[a].q=c[a].q||[]).push(arguments) };
      t=l.createElement(r); t.async=1; t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t,y);
    })(window, document, 'clarity', 'script', 'lh3rrqe4na');`}
            </script>
            <meta name="robots" content="noindex" />
          </Helmet>
          <div className="font-montserrat relative">
            <ChatBubble>
              <WhatsAppLink />
            </ChatBubble>
            <Routes>
              <Route
                path="/"
                element={
                  <NewTutorGuard>
                    <>
                      <LanguageLanding />
                    </>
                  </NewTutorGuard>
                }
              />
              <Route
                path={"/stripe"}
                element={
                  <StripePaymentForm
                    order={{
                      order_amount: {
                        currency: "USD",
                        value: 1000,
                      },
                      email: "",
                    }}
                  />
                }
              />
              <Route
                path={routes.DIRECT_TO_PAY_WELCOME.url}
                element={
                  <GuestGuard>
                    <DirectToPayWelcome />
                  </GuestGuard>
                }
              />
              <Route
                path={"/welcome/new-calendar"}
                element={<NewCalendarView />}
              />
              <Route
                path={routes.DIRECT_TO_PAY_WELCOME_B.url}
                element={
                  <GuestGuard>
                    <DirectToPayAlternate />
                  </GuestGuard>
                }
              />
              <Route
                path={routes.DIRECT_TO_PAY_GET_STARTED_B.url}
                element={
                  <>
                    <DirectToPayAlternateGetStarted />
                  </>
                }
              />
              <Route
                path={routes.DIRECT_TO_PAY_MEET_TUTORS.url}
                element={
                  <>
                    <DirectToPayMeetTutors />
                  </>
                }
              />
              <Route
                path={routes.DIRECT_TO_PAY_MEET_TUTORS_B.url}
                element={
                  <>
                    <DirectToPayMeetTutors />
                  </>
                }
              />
              <Route
                path={`${routes.DIRECT_TO_PAY_WELCOME_B.url}/payment-plans`}
                element={
                  <>
                    <DirectToPayAlternatePaymentPlans />
                  </>
                }
              />
              <Route
                path={routes.ACADEMIC.url}
                element={
                  <NewTutorGuard>
                    <>
                      <Home />
                    </>
                  </NewTutorGuard>
                }
              />
              <Route
                path={routes.PRIVACY_POLICY.url}
                element={
                  <NewTutorGuard>
                    <>
                      <PrivacyPolicy />
                    </>
                  </NewTutorGuard>
                }
              />
              <Route
                path={routes.COOKIE_POLICY.url}
                element={
                  <NewTutorGuard>
                    <>
                      <CookiePolicy />
                    </>
                  </NewTutorGuard>
                }
              />
              <Route path={routes.ABOUT.url} element={<About />} />
              <Route
                path={routes.YORUBA_LANDING.url}
                element={
                  <NewTutorGuard>
                    <>
                      <YorubaLanding />
                    </>
                  </NewTutorGuard>
                }
              />
              <Route
                path={routes.TERMS.url}
                element={
                  <>
                    <Terms />
                  </>
                }
              />
              <Route
                path={routes.YORUBA_ROADMAP.url}
                element={
                  <>
                    <YorubaRoadmap />
                  </>
                }
              />
              <Route
                path={routes.CHECKOUT.url}
                element={
                  <>
                    <Checkout />
                  </>
                }
              />
              <Route
                path={routes.IGBO_ROADMAP.url}
                element={
                  <>
                    <IgboRoadmap />
                  </>
                }
              />
              <Route
                path={routes.HAUSA_ROADMAP.url}
                element={
                  <>
                    <HausaRoadmap />
                  </>
                }
              />
              <Route
                path={routes.CONTACT.url}
                element={
                  <NewTutorGuard>
                    <>
                      <Contact />
                    </>
                  </NewTutorGuard>
                }
              />
              <Route
                path={routes.SERVICES.url}
                element={
                  <NewTutorGuard>
                    <>
                      <Services />
                    </>
                  </NewTutorGuard>
                }
              />
              <Route
                path={routes.FAQS.url}
                element={
                  <>
                    <Faqs />
                  </>
                }
              />
              <Route
                path={routes.CHEMISTRY.url}
                element={
                  <NewTutorGuard>
                    <>
                      <Chemistry />
                    </>
                  </NewTutorGuard>
                }
              />
              <Route
                path={routes.LANGUAGE.url}
                element={
                  <NewTutorGuard>
                    <>
                      <Languages />
                    </>
                  </NewTutorGuard>
                }
              />
              <Route
                path="/tutor-signup"
                element={
                  <GuestGuard>
                    <>
                      <TutorSignup />
                    </>
                  </GuestGuard>
                }
              />
              <Route
                path="/tutor-email-signup"
                element={
                  <GuestGuard>
                    <>
                      <TutorEmailSignup />
                    </>
                  </GuestGuard>
                }
              />
              <Route
                path={routes.LOGIN.url}
                element={
                  <GuestGuard>
                    <>
                      <LoginForm />
                    </>
                  </GuestGuard>
                }
              />
              <Route
                path={routes.STUDENT_SIGNUP.url}
                element={
                  <GuestGuard>
                    <>
                      <StudentSignup />
                    </>
                  </GuestGuard>
                }
              />
              <Route
                path={routes.STUDENT_EMAIL_SIGNUP.url}
                element={
                  <GuestGuard>
                    <>
                      <StudentEmailSignup />
                    </>
                  </GuestGuard>
                }
              />
              <Route
                path={`${routes.STUDENT_LESSON_FEEDBACK.url}/:lessonId`}
                element={
                  <>
                    <StudentLessonFeedback />
                  </>
                }
              />
              <Route
                path={`${routes.DEPENDANT_DASHBOARD_MESSAGES.url}/:dependantName/:dependantId`}
                element={
                  <NonStudentGuard>
                    <StudentDashboardLayout>
                      <>
                        <Messenger />
                      </>
                    </StudentDashboardLayout>
                  </NonStudentGuard>
                }
              />
              <Route
                path={`${routes.DEPENDANT_DASHBOARD_MESSAGES.url}/:dependantName/:dependantId/:receiverId`}
                element={
                  <NonStudentGuard>
                    <StudentDashboardLayout>
                      <>
                        <Messenger />
                      </>
                    </StudentDashboardLayout>
                  </NonStudentGuard>
                }
              />
              <Route
                path="/tutor/onboarding/step0"
                element={
                  <>
                    <OnboardingStep0 />
                  </>
                }
              />
              <Route
                path={`${routes.GOOGLE_CALLBACK.url}`}
                element={<GoogleCallback />}
              />
              <Route
                path="/tutor/onboarding/step1"
                element={
                  <>
                    <OnboardingStep1 />
                  </>
                }
              />
              <Route
                path={routes.ONBOARDING_STEP_ABOUT_YOURSELF.url}
                element={
                  <>
                    <OnboardingStep05 />
                  </>
                }
              />
              <Route
                path={`${routes.STUDENT_DASHBOARD_VIRTUAL_IMMERSIONS.url}/:subjectId`}
                element={
                  <AuthGuard>
                    <StudentDashboardLayout>
                      <>
                        <VirtualImmersions />
                      </>
                    </StudentDashboardLayout>
                  </AuthGuard>
                }
              />
              <Route
                path={`${routes.VIRTUAL_IMMERSIONS.url}/:subjectId`}
                element={
                  <AuthGuard>
                    <OuterPagesLayout>
                      <>
                        <VirtualImmersions />
                      </>
                    </OuterPagesLayout>
                  </AuthGuard>
                }
              />
              <Route
                path={routes.TEACHER_REFERRAL_PROGRAM_SIGNUP.url}
                element={
                  <>
                    <TeacherReferralSignup />
                  </>
                }
              />
              <Route
                path={routes.TUTOR_DASHBOARD_OVERVIEW.url}
                element={
                  <NonTutorGuard>
                    <TutorDashboardLayout>
                      <>
                        <TutorOverview />
                      </>
                    </TutorDashboardLayout>
                  </NonTutorGuard>
                }
              />
              <Route
                path={routes.TUTOR_DASHBOARD_LESSON_REWARDS.url}
                element={
                  <NonTutorGuard>
                    <TutorDashboardLayout>
                      <>
                        <LessonRewards />
                      </>
                    </TutorDashboardLayout>
                  </NonTutorGuard>
                }
              />
              <Route
                path={`${routes.REPORT_NO_SHOW.url}/:lessonId`}
                element={
                  <NonTutorGuard>
                    <TutorDashboardLayout>
                      <>
                        <ReportNoShow />
                      </>
                    </TutorDashboardLayout>
                  </NonTutorGuard>
                }
              />
              <Route
                path={`${routes.SENTENCE_CONSTRUCTION_SELECTION.url}/:level/:order/:topic/:topicId/:curriculumId/:parentDivisionId`}
                element={
                  <AuthGuard>
                    <StudentDashboardLayout>
                      <>
                        <SentenceContructionSelection />
                      </>
                    </StudentDashboardLayout>
                  </AuthGuard>
                }
              />
              <Route
                path={`${routes.SENTENCE_CONSTRUCTION_SESSION.url}/:sessionId`}
                element={
                  <AuthGuard>
                    <StudentDashboardLayout>
                      <>
                        <SentenceConstructionSession />
                      </>
                    </StudentDashboardLayout>
                  </AuthGuard>
                }
              />
              <Route
                path={`${routes.SENTENCE_CONSTRUCTION.url}/:curriculumId/:parentDivisionId`}
                element={
                  <AuthGuard>
                    <StudentDashboardLayout>
                      <>
                        <SentenceBuilder />
                      </>
                    </StudentDashboardLayout>
                  </AuthGuard>
                }
              />
              <Route
                path={`${routes.SENTENCE_BUILDER.url}`}
                element={
                  <>
                    <SentenceBuilder />
                  </>
                }
              />
              {/* <Route
                path={`${routes.LEARNING_APP.url}`}
                element={
                  <>
                    <LeaningAppLanding />
                  </>
                }
              /> */}
              <Route
                path={`${routes.LEARNING_APP.url}/:languageId/:levelId/:sessionId`}
                element={
                  <AuthGuard>
                    <>
                      <LearningApp />
                    </>
                  </AuthGuard>
                }
              />
              <Route
                path={`${routes.LEARNING_APP.url}/:languageId`}
                element={
                  <AuthGuard>
                    <>
                      <LanguageLanguageLanding />
                    </>
                  </AuthGuard>
                }
              />
              <Route
                path={`${routes.LEARNING_APP.url}/:languageId/:levelId`}
                element={
                  <AuthGuard>
                    <>
                      <LanguageLevelLanding />
                    </>
                  </AuthGuard>
                }
              />
              <Route
                path={`${routes.WORD_GENERATOR.url}/:languageId`}
                element={
                  <AuthGuard>
                    <>
                      <WordGenerator />
                    </>
                  </AuthGuard>
                }
              />
              <Route
                path={routes.TUTOR_DASHBOARD_MESSAGES.url}
                element={
                  <NonTutorGuard>
                    <TutorDashboardLayout>
                      <>
                        <Messenger />
                      </>
                    </TutorDashboardLayout>
                  </NonTutorGuard>
                }
              />

              <Route
                path={routes.TUTOR_DASHBOARD_BIO.url}
                element={
                  <NonTutorGuard>
                    <TutorDashboardLayout>
                      <>
                        <TutorBio />
                      </>
                    </TutorDashboardLayout>
                  </NonTutorGuard>
                }
              />
              <Route
                path={routes.TUTOR_DASHBOARD_BOOKINGS.url}
                element={
                  <NonTutorGuard>
                    <TutorDashboardLayout>
                      <>
                        <TutorLessonRequests />
                      </>
                    </TutorDashboardLayout>
                  </NonTutorGuard>
                }
              />
              <Route
                path={routes.TUTOR_DASHBOARD_ONBOARDING.url}
                element={
                  <NonTutorGuard>
                    <TutorDashboardLayout>
                      <>
                        <TutorOnboarding />
                      </>
                    </TutorDashboardLayout>
                  </NonTutorGuard>
                }
              />
              <Route
                path={routes.FIND_A_TUTOR.url}
                element={
                  <OuterPagesLayout>
                    <>
                      <FindATutor />
                    </>
                  </OuterPagesLayout>
                }
              />
              <Route
                path={routes.PRICES.url}
                element={
                  <OuterPagesLayout>
                    <>
                      <LessonPrice />
                    </>
                  </OuterPagesLayout>
                }
              />
              <Route
                path={routes.FORGOT_PASSWORD.url}
                element={
                  <GuestGuard>
                    <>
                      <ForgotPassword />
                    </>
                  </GuestGuard>
                }
              />
              <Route
                path={`${routes.UPDATE_PASSWORD.url}/:userId/:token`}
                element={
                  <GuestGuard>
                    <>
                      <UpdatePassword />
                    </>
                  </GuestGuard>
                }
              />
              <Route
                path={`${routes.USER_IMPERSONATION.url}/:userId/:token`}
                element={
                  <>
                    <UserImpersonation />
                  </>
                }
              />
              <Route
                path={`${routes.VERIFY_EMAIL.url}/:userId`}
                element={
                  <>
                    <VerifyEmail />
                  </>
                }
              />
              <Route
                path={`${routes.CREATE_PASSWORD.url}/:userId/:token`}
                element={
                  <GuestGuard>
                    <>
                      <CreatePassword />
                    </>
                  </GuestGuard>
                }
              />
              <Route
                path={`${routes.RESET_PASSWORD.url}/:userId/:token`}
                element={
                  <GuestGuard>
                    <>
                      <UpdatePassword />
                    </>
                  </GuestGuard>
                }
              />
              <Route
                path={routes.FIND_GROUP_LESSONS.url}
                element={
                  <OuterPagesLayout>
                    <>
                      <FindGroupLesson />
                    </>
                  </OuterPagesLayout>
                }
              />
              <Route
                path={`${routes.VIEW_GROUP_LESSON.url}/:courseName/:groupLessonId`}
                element={
                  <OuterPagesLayout>
                    <>
                      <ViewGroupLessonPage />
                    </>
                  </OuterPagesLayout>
                }
              />
              <Route
                path={`${routes.BOOK_A_GROUP_LESSON.url}/:courseName/:groupLessonId`}
                element={
                  <NonStudentGuard>
                    <OuterPagesLayout>
                      <>
                        <BookAGroupLesson />
                      </>
                    </OuterPagesLayout>
                  </NonStudentGuard>
                }
              />
              <Route
                path={`${routes.VIEW_TUTOR_PROFILE.url}/:tutorName/:tutorId`}
                element={
                  <OuterPagesLayout>
                    <>
                      <ViewTutorPage />
                    </>
                  </OuterPagesLayout>
                }
              />
              <Route
                path={`${routes.VIEW_TUTOR_PROFILE_DIRECT_PAY.url}/:tutorName/:tutorId`}
                element={<ViewTutorProfile />}
              />

              <Route
                path={`${routes.DIRECT_TO_PAY_MEET_TUTORS_B.url}/:tutorName/:tutorId`}
                element={<ViewTutorProfile />}
              />
              <Route
                path={`${routes.BOOK_A_LESSON.url}/:tutorName/:tutorId`}
                element={
                  <NonStudentGuard>
                    <OuterPagesLayout>
                      <>
                        <BookALesson />
                      </>
                    </OuterPagesLayout>
                  </NonStudentGuard>
                }
              />
              <Route
                path={`${routes.BOOK_A_LESSON_VIA_ADMIN.url}`}
                element={
                  <OuterPagesLayout>
                    <BookALessonViaAdminNoAuth />
                  </OuterPagesLayout>
                }
              />
              <Route
                path={`${routes.ONBOARDING_EXERCISE.url}/:subjectName/:type/:subjectId/:demoId`}
                element={
                  <>
                    <OnboardingExercise />
                  </>
                }
              />
              <Route
                path={`${routes.TEACHER_REFERRAL_PROGRAM_HOME.url}`}
                element={
                  <NonTeacherReferrerGuard>
                    <TeacherReferralDashboardLayout>
                      <>
                        <ReferralHome />
                      </>
                    </TeacherReferralDashboardLayout>
                  </NonTeacherReferrerGuard>
                }
              />
              <Route
                path={`${routes.TEACHER_REFERRAL_DASHBOARD_SETTINGS.url}`}
                element={
                  <NonTeacherReferrerGuard>
                    <TeacherReferralDashboardLayout>
                      <>
                        <ProfileSettings />
                      </>
                    </TeacherReferralDashboardLayout>
                  </NonTeacherReferrerGuard>
                }
              />
              <Route
                path={`${routes.TEACHER_REFERRAL_PROGRAM_PROFILE.url}`}
                element={
                  <NonTeacherReferrerGuard>
                    <TeacherReferralDashboardLayout>
                      <>
                        <TeacherBio />
                      </>
                    </TeacherReferralDashboardLayout>
                  </NonTeacherReferrerGuard>
                }
              />
              <Route
                path={routes.BECOME_A_TUTOR.url}
                element={
                  <>
                    <BecomeATutor />
                  </>
                }
              />
              <Route
                path={routes.BECOME_A_TUTOR_NEXT_STEPS.url}
                element={
                  <>
                    <NextSteps />
                  </>
                }
              />
              <Route
                path="*"
                element={
                  <>
                    <Error404 />
                  </>
                }
              />
              <Route
                path={`${routes.ACADEMIC_THANK_YOU.url}`}
                element={
                  <OuterPagesLayout>
                    <ThankYou category="academic" />
                  </OuterPagesLayout>
                }
              />
              <Route
                path={`${routes.LANGUAGE_THANK_YOU.url}`}
                element={
                  <OuterPagesLayout>
                    <ThankYou category="language" />
                  </OuterPagesLayout>
                }
              />
              <Route
                path={`${routes.CHECKOUT_THANK_YOU.url}`}
                element={
                  <OuterPagesLayout>
                    <CheckoutThankYou />
                  </OuterPagesLayout>
                }
              />
              <Route
                path={`${routes.CHECKOUT_PAYMENT_CONFIRMATION.url}`}
                element={
                  <OuterPagesLayout>
                    <PaymentConfirmation />
                  </OuterPagesLayout>
                }
              />
              <Route
                path={`${routes.DIRECT_TO_PAY_WELCOME_B.url}/lesson-confirmation`}
                element={
                  <OuterPagesLayout>
                    <PaymentConfirmation />
                  </OuterPagesLayout>
                }
              />
              <Route
                path={routes.STUDENT_DASHBOARD_BIO.url}
                element={
                  <NonStudentGuard>
                    <StudentDashboardLayout>
                      <>
                        <StudentBio />
                      </>
                    </StudentDashboardLayout>
                  </NonStudentGuard>
                }
              />
              <Route
                path={routes.STUDENT_DASHBOARD_NOTIFICATIONS.url}
                element={
                  <NonStudentGuard>
                    <StudentDashboardLayout>
                      <>
                        <Notifications />
                      </>
                    </StudentDashboardLayout>
                  </NonStudentGuard>
                }
              />
              <Route
                path={routes.STUDENT_DASHBOARD_MINIGAMES.url}
                element={
                  <NonStudentGuard>
                    <StudentDashboardLayout>
                      <>
                        <ScratchGames />
                      </>
                    </StudentDashboardLayout>
                  </NonStudentGuard>
                }
              />
              <Route
                path={`${routes.STUDENT_DASHBOARD_SCRATCHGAMES.url}/:projectId`}
                element={
                  <NonStudentGuard>
                    <StudentDashboardLayout>
                      <>
                        <GamePage />
                      </>
                    </StudentDashboardLayout>
                  </NonStudentGuard>
                }
              />
              <Route
                path={routes.STUDENT_DASHBOARD_REWARDS.url}
                element={
                  <NonStudentGuard>
                    <StudentDashboardLayout>
                      <>
                        <Rewards />
                      </>
                    </StudentDashboardLayout>
                  </NonStudentGuard>
                }
              />
              <Route
                path={routes.STUDENT_LEADERBOARD.url}
                element={
                  <NonStudentGuard>
                    <StudentDashboardLayout>
                      <>
                        <Leaderboard />
                      </>
                    </StudentDashboardLayout>
                  </NonStudentGuard>
                }
              />
              <Route
                path={routes.TUTOR_DASHBOARD_NOTIFICATIONS.url}
                element={
                  <NonTutorGuard>
                    <TutorDashboardLayout>
                      <>
                        <Notifications />
                      </>
                    </TutorDashboardLayout>
                  </NonTutorGuard>
                }
              />
              <Route
                path={routes.TUTOR_PAYMENT_INFO.url}
                element={
                  <NonTutorGuard>
                    <TutorDashboardLayout>
                      <>
                        <PaymentInfo />
                      </>
                    </TutorDashboardLayout>
                  </NonTutorGuard>
                }
              />
              <Route
                path={routes.STUDENT_DASHBOARD_SETTINGS.url}
                element={
                  <NonStudentGuard>
                    <StudentDashboardLayout>
                      <>
                        <StudentSettings />
                      </>
                    </StudentDashboardLayout>
                  </NonStudentGuard>
                }
              />
              <Route
                path={routes.STUDENT_DASHBOARD_PAYMENTS.url}
                element={
                  <NonStudentGuard>
                    <StudentDashboardLayout>
                      <>
                        <StudentPayment />
                      </>
                    </StudentDashboardLayout>
                  </NonStudentGuard>
                }
              />
              <Route
                path={routes.STUDENT_DASHBOARD_ACADEMIC_INFO.url}
                element={
                  <NonStudentGuard>
                    <StudentDashboardLayout>
                      <>
                        <AcademicInfo />
                      </>
                    </StudentDashboardLayout>
                  </NonStudentGuard>
                }
              />
              <Route
                path={`${routes.STUDENT_DASHBOARD_BOOKINGS.url}`}
                element={
                  <NonStudentGuard>
                    <StudentDashboardLayout>
                      <>
                        <StudentLessonRequestsToAdmin />
                      </>
                    </StudentDashboardLayout>
                  </NonStudentGuard>
                }
              />
              {/* <Route
                path={`${routes.STUDENT_DASHBOARD_RESOURCES.url}`}
                element={
                  <NonStudentGuard>
                    <StudentDashboardLayout>
                      <StudentResources />
                    </StudentDashboardLayout>
                  </NonStudentGuard>
                }
              /> */}
              <Route
                path={`${routes.STUDENT_DASHBOARD_LEARN.url}`}
                element={
                  <NonStudentGuard>
                    <StudentDashboardLayout>
                      <StudentResources />
                    </StudentDashboardLayout>
                  </NonStudentGuard>
                }
              />
              <Route
                path={`${routes.STUDENT_DASHBOARD_LEARN.url}/:level/:order/:topic/:topicId/:curriculumId/:parentDivisionId`}
                element={
                  <NonStudentGuard>
                    <StudentDashboardLayout>
                      <TopicDetails />
                    </StudentDashboardLayout>
                  </NonStudentGuard>
                }
              />
              <Route
                path={`${routes.STUDENT_DASHBOARD_LEARN.url}/:level/:order/:topic/:session`}
                element={
                  <NonStudentGuard>
                    <StudentDashboardLayout>
                      <SessionDetails />
                    </StudentDashboardLayout>
                  </NonStudentGuard>
                }
              />
              <Route
                path={`${routes.STUDENT_DASHBOARD_UPCOMING_LESSONS.url}`}
                element={
                  <NonStudentGuard>
                    <StudentDashboardLayout>
                      <>
                        <StudentUpcomingLessonsViaAdmin />
                      </>
                    </StudentDashboardLayout>
                  </NonStudentGuard>
                }
              />
              <Route
                path={`${routes.STUDENT_DASHBOARD_PAST_LESSONS.url}`}
                element={
                  <NonStudentGuard>
                    <StudentDashboardLayout>
                      <>
                        <StudentLessonHistoryViaAdmin />
                      </>
                    </StudentDashboardLayout>
                  </NonStudentGuard>
                }
              />
              <Route
                path={`${routes.STUDENT_DASHBOARD_HOMEWORK.url}`}
                element={
                  <NonStudentGuard>
                    <StudentDashboardLayout>
                      <>
                        <StudentHomework />
                      </>
                    </StudentDashboardLayout>
                  </NonStudentGuard>
                }
              />
              <Route
                path={`${routes.STUDENT_DASHBOARD_MESSAGES.url}`}
                element={
                  <NonStudentGuard>
                    <StudentDashboardLayout>
                      <>
                        <Messenger />
                      </>
                    </StudentDashboardLayout>
                  </NonStudentGuard>
                }
              />
              <Route
                path={`${routes.STUDENT_DASHBOARD_MESSAGES.url}/:receiverName/:receiverId`}
                element={
                  <NonStudentGuard>
                    <StudentDashboardLayout>
                      <>
                        <Messenger />
                      </>
                    </StudentDashboardLayout>
                  </NonStudentGuard>
                }
              />
              <Route
                path={`${routes.DEPENDANT_DASHBOARD_MESSAGES.url}/:dependantName/:dependantId`}
                element={
                  <NonStudentGuard>
                    <StudentDashboardLayout>
                      <>
                        <Messenger />
                      </>
                    </StudentDashboardLayout>
                  </NonStudentGuard>
                }
              />
              <Route
                path={`${routes.DEPENDANT_DASHBOARD_MESSAGES.url}/:dependantName/:dependantId/:receiverId`}
                element={
                  <NonStudentGuard>
                    <StudentDashboardLayout>
                      <>
                        <Messenger />
                      </>
                    </StudentDashboardLayout>
                  </NonStudentGuard>
                }
              />
              <Route
                path="/tutor/onboarding/step0"
                element={
                  <>
                    <OnboardingStep0 />
                  </>
                }
              />
              <Route
                path="/tutor/onboarding/step1"
                element={
                  <>
                    <OnboardingStep1 />
                  </>
                }
              />
              <Route
                path={routes.ONBOARDING_STEP_ABOUT_YOURSELF.url}
                element={
                  <>
                    <OnboardingStep05 />
                  </>
                }
              />
              <Route
                path={routes.TEACHER_REFERRAL_PROGRAM_SIGNUP.url}
                element={
                  <>
                    <TeacherReferralSignup />
                  </>
                }
              />
              <Route
                path={routes.TUTOR_DASHBOARD_OVERVIEW.url}
                element={
                  <NonTutorGuard>
                    <TutorDashboardLayout>
                      <>
                        <TutorOverview />
                      </>
                    </TutorDashboardLayout>
                  </NonTutorGuard>
                }
              />
              <Route
                path={routes.STUDENT_REFERRAL.url}
                element={
                  <NonStudentGuard>
                    <StudentDashboardLayout>
                      <>
                        <StudentReferral />
                      </>
                    </StudentDashboardLayout>
                  </NonStudentGuard>
                }
              />
              <Route
                path={routes.TUTOR_DASHBOARD_MESSAGES.url}
                element={
                  <NonTutorGuard>
                    <TutorDashboardLayout>
                      <>
                        <Messenger />
                      </>
                    </TutorDashboardLayout>
                  </NonTutorGuard>
                }
              />
              <Route
                path={routes.TUTOR_DASHBOARD_BIO.url}
                element={
                  <NonTutorGuard>
                    <TutorDashboardLayout>
                      <>
                        <TutorBio />
                      </>
                    </TutorDashboardLayout>
                  </NonTutorGuard>
                }
              />
              <Route
                path={routes.TUTOR_DASHBOARD_BOOKINGS.url}
                element={
                  <NonTutorGuard>
                    <TutorDashboardLayout>
                      <>
                        <TutorLessonRequests />
                      </>
                    </TutorDashboardLayout>
                  </NonTutorGuard>
                }
              />
              <Route
                path={routes.TUTOR_DASHBOARD_ONBOARDING.url}
                element={
                  <NonTutorGuard>
                    <TutorDashboardLayout>
                      <>
                        <TutorOnboarding />
                      </>
                    </TutorDashboardLayout>
                  </NonTutorGuard>
                }
              />
              <Route
                path={routes.TUTOR_DASHBOARD_SETTINGS.url}
                element={
                  <NonTutorGuard>
                    <TutorDashboardLayout>
                      <>
                        <TutorSettings />
                      </>
                    </TutorDashboardLayout>
                  </NonTutorGuard>
                }
              />
              <Route
                path={routes.TUTOR_DASHBOARD_LESSONS.url}
                element={
                  <NonTutorGuard>
                    <TutorDashboardLayout>
                      <>
                        <TutorLessons />
                      </>
                    </TutorDashboardLayout>
                  </NonTutorGuard>
                }
              />
              <Route
                path={routes.TUTOR_DASHBOARD_HOMEWORK.url}
                element={
                  <NonTutorGuard>
                    <TutorDashboardLayout>
                      <>
                        <TutorHomework />
                      </>
                    </TutorDashboardLayout>
                  </NonTutorGuard>
                }
              />
              <Route
                path={routes.TUTOR_DASHBOARD_PAST_LESSONS.url}
                element={
                  <NonTutorGuard>
                    <TutorDashboardLayout>
                      <>
                        <TutorLessonHistoryViaAdmin />
                      </>
                    </TutorDashboardLayout>
                  </NonTutorGuard>
                }
              />
              <Route
                path={routes.TUTOR_DASHBOARD_UPCOMING_LESSONS.url}
                element={
                  <NonTutorGuard>
                    <TutorDashboardLayout>
                      <>
                        <TutorUpcomingLessonsViaAdmin />
                      </>
                    </TutorDashboardLayout>
                  </NonTutorGuard>
                }
              />
              <Route
                path={routes.TUTOR_DASHBOARD_STUDENTS.url}
                element={
                  <NonTutorGuard>
                    <TutorDashboardLayout>
                      <>
                        <TutorStudents />
                      </>
                    </TutorDashboardLayout>
                  </NonTutorGuard>
                }
              />
              <Route
                path={routes.TUTOR_DASHBOARD_AVAILABILITY.url}
                element={
                  <NonTutorGuard>
                    <TutorDashboardLayout>
                      <>
                        <TutorAvailability />
                      </>
                    </TutorDashboardLayout>
                  </NonTutorGuard>
                }
              />
              <Route
                path={routes.TUTOR_DASHBOARD_MESSAGES.url}
                element={
                  <NonTutorGuard>
                    <TutorDashboardLayout>
                      <>
                        <Messenger />
                      </>
                    </TutorDashboardLayout>
                  </NonTutorGuard>
                }
              />
              <Route
                path={`${routes.TUTOR_DASHBOARD_MESSAGES.url}/:receiverName/:receiverId`}
                element={
                  <NonTutorGuard>
                    <TutorDashboardLayout>
                      <>
                        <Messenger />
                      </>
                    </TutorDashboardLayout>
                  </NonTutorGuard>
                }
              />
              <Route
                path={`${routes.ONBOARDING_EXERCISE.url}/:subjectName/:type/:subjectId/:demoId`}
                element={
                  <>
                    <OnboardingExercise />
                  </>
                }
              />
              <Route
                path={routes.BECOME_A_TUTOR.url}
                element={
                  <>
                    <BecomeATutor />
                  </>
                }
              />
              <Route
                path={routes.BECOME_A_TUTOR_NEXT_STEPS.url}
                element={
                  <>
                    <NextSteps />
                  </>
                }
              />
              <Route
                path={`${routes.STUDENT_DASHBOARD_FEEDBACK.url}/:lessonId`}
                element={
                  <NonStudentGuard>
                    <StudentDashboardLayout>
                      <>
                        <StudentDashboardFeedback />
                      </>
                    </StudentDashboardLayout>
                  </NonStudentGuard>
                }
              />
              <Route
                path={`${routes.STUDENT_DASHBOARD_FEEDBACK.url}`}
                element={
                  <NonStudentGuard>
                    <StudentDashboardLayout>
                      <>
                        <StudentAllFeedback />
                      </>
                    </StudentDashboardLayout>
                  </NonStudentGuard>
                }
              />
              <Route
                path={`${routes.TUTOR_DASHBOARD_FEEDBACK.url}/view-feedback/:student`}
                element={
                  <NonTutorGuard>
                    <TutorDashboardLayout>
                      <>
                        <StudentDashboardFeedback />
                      </>
                    </TutorDashboardLayout>
                  </NonTutorGuard>
                }
              />
              <Route
                path={`${routes.TUTOR_DASHBOARD_STUDENT_PROFILE.url}/:studentId`}
                element={
                  <NonTutorGuard>
                    <TutorDashboardLayout>
                      <>
                        <StudentProfile />
                      </>
                    </TutorDashboardLayout>
                  </NonTutorGuard>
                }
              />
              <Route
                path={`${routes.TUTOR_DASHBOARD_FEEDBACK.url}/:student`}
                element={
                  <NonTutorGuard>
                    <TutorDashboardLayout>
                      <>
                        <TutorFeedback />
                      </>
                    </TutorDashboardLayout>
                  </NonTutorGuard>
                }
              />
              <Route
                path={`${routes.TUTOR_DASHBOARD_STUDENT_NOTES.url}/:student`}
                element={
                  <NonTutorGuard>
                    <TutorDashboardLayout>
                      <>
                        <StudentNotes />
                      </>
                    </TutorDashboardLayout>
                  </NonTutorGuard>
                }
              />
              <Route
                path={`${routes.TUTOR_DASHBOARD_FEEDBACK.url}`}
                element={
                  <NonTutorGuard>
                    <TutorDashboardLayout>
                      <>
                        <AllTutorFeedback />
                      </>
                    </TutorDashboardLayout>
                  </NonTutorGuard>
                }
              />
              <Route
                path={routes.CHOOSE_A_LANGUAGE.url}
                element={<ChooseLanguage />}
              />
              <Route
                path="*"
                element={
                  <>
                    <Error404 />
                  </>
                }
              />
            </Routes>
          </div>
        </Auth>
      </Suspense>
    </Router>
  );
}

export default App;
