import React from "react";
import { useLocation, useParams } from "react-router-dom";
import Breadcrumb from "src/components/StudentDashboard/Learn/Breadcrumb";
import { useStateValue } from "src/context/StateProvider";
import {
  LessonResources,
  lessonResources,
} from "src/static-data/lessonResources";
import { VideoComponent } from "./VideoComponent";

export default function SessionDetails() {
  const { state }: any = useLocation();
  const { level, topic } = useParams();

  const [{ userLessonProgress }] = useStateValue();

  const practiceVideo = (lessonResources as LessonResources)?.[
    userLessonProgress?.curriculum
  ]?.[userLessonProgress.ageGroup]?.[level as any]?.[topic as any]?.resources
    .practiceVideo;
  const grammarSpotlight = (lessonResources as LessonResources)?.[
    userLessonProgress?.curriculum
  ]?.[userLessonProgress.ageGroup]?.[level as any]?.[topic as any]?.resources
    .grammarSpotlight;
  return (
    <section className="text-primary">
      <Breadcrumb />
      {state?.id === 3 && <VideoComponent practiceVideo={practiceVideo} />}
      {state?.id === 1 && <GrammarSpotlight content={grammarSpotlight} />}
    </section>
  );
}

const GrammarSpotlight = ({ content }: any) => {
  // Function to convert markdown-style formatting to HTML
  const formatContent = (text: string) => {
    return text
      .split("\n")
      .map((line) => line.trim())
      .filter((line) => line !== "")
      .map((line) => {
        // Convert **bold** to <strong>bold</strong>
        line = line.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
        return `<p>${line}</p>`;
      })
      .join("");
  };

  const formattedContent = formatContent(content);

  return (
    <div className="max-w-4xl mx-auto mt-4 p-4 ">
      {/* <h2 className="text-xl font-bold mb-2">Grammar Spotlight</h2> */}
      <div
        className="prose"
        dangerouslySetInnerHTML={{ __html: formattedContent }}
      />
    </div>
  );
};
