import React from "react";
import HeaderText from "./HeaderText";
import { useDirectPayContext } from "src/context/DirectPayContext";
import PhoneInput from "react-phone-input-2";
import { removeZeroPrefix } from "src/utils/helper/removePhoneZeroPrefix";
import { useStateValue } from "src/context/StateProvider";

export default function UserDataForm() {
  const {
    state: { userData, error },
    actions: { setUserData },
  } = useDirectPayContext();
  const [{ clientIpCountry }] = useStateValue();

  return (
    <div className="mt-8">
      <section className="max-w-xl mx-auto">
        <HeaderText>Tell Us About Yourself</HeaderText>
        <div className="max-w-md mt-7 mx-auto">
          <label
            htmlFor="firstName"
            className="text-lg font-semibold text-left w-full block "
          >
            First Name
          </label>
          <input
            className="mt-2 w-full rounded-2xl border-gray-100 bg-gray-50 outline-none py-4 px-4"
            style={{ borderWidth: "3px" }}
            type="text"
            id="firstName"
            name='firstname'
            placeholder="First Name"
            value={userData.firstname}
            onChange={(e) => {
              setUserData("firstname", e.target.value);
            }}
          />

          <p className="text-red-500 text-left text-sm">
            {error?.field === "userData" &&
              error.errors.find((err) => err.field === "firstname")?.message}
          </p>

          <label
            htmlFor="lastName"
            className="text-lg font-semibold text-left w-full block mt-4"
          >
            Last Name
          </label>
          <input
            className="mt-2 w-full rounded-2xl border-gray-100 bg-gray-50 outline-none py-4 px-4"
            style={{ borderWidth: "3px" }}
            type="text"
            id="lastName"
            placeholder="Last Name"
            name='lastname'
            value={userData.lastname}
            onChange={(e) => {
              setUserData("lastname", e.target.value);
            }}
          />

          <p className="text-red-500 text-left text-sm">
            {error?.field === "userData" &&
              error.errors.find((err) => err.field === "lastname")?.message}
          </p>
          <label
            htmlFor="email"
            className="text-lg font-semibold text-left w-full block mt-4"
          >
            Email
          </label>
          <input
            className="mt-2 w-full rounded-2xl border-gray-100 bg-gray-50 outline-none py-4 px-4"
            style={{ borderWidth: "3px" }}
            type="email"
            id="email"
            name='email'
            placeholder="Email"
            value={userData.email}
            onChange={(e) => {
              setUserData("email", e.target.value);
            }}
          />
          <p className="text-red-500 text-left text-sm">
            {error?.field === "userData" &&
              error.errors.find((err) => err.field === "email")?.message}
          </p>
          <label
            htmlFor="phone"
            className="text-lg font-semibold text-left w-full block mt-4"
          >
            Phone Number
          </label>

          <PhoneInput
            inputStyle={{
              width: "100%",
              border: 0,
              backgroundColor: "transparent",
            }}
            buttonStyle={{
              border: 0,
              backgroundColor: "transparent",
            }}
            country={clientIpCountry?.toLowerCase()}
            containerStyle={{
              marginTop: "0.5rem",
              width: "100%",
              borderRadius: "1rem",
              border: "1px solid #f3f4f6",
              backgroundColor: "#f9fafb",
              outline: "none",
              padding: "1rem",
              borderWidth: "3px",
            }}
            value={userData.phone_number}
            onChange={(phone, country: any) => {
              const { phoneFormat } = removeZeroPrefix(phone, country);

              setUserData("phone_number", phoneFormat);
              setUserData("locationInfo", country);
            }}
            inputProps={{
              'data-test':"phoneNumber"
            }}
          />
          <p className="text-red-500 text-left text-sm">
            {error?.field === "userData" &&
              error.errors.find((err) => err.field === "phone_number")?.message}
          </p>
        </div>
      </section>
    </div>
  );
}
