import Landing from "./Landing";
import { useContext, useEffect, useState } from "react";
import { Context as DirectPayContext } from "src/context/DirectPayContext";

import { TrustpilotProvider } from "src/context/TrustpilotContext";

import FindingPerfectTutorMatch from "src/components/LottieAnimations/FindingPerfectTutorMatch";
import FormLogic from "./FormLogic";
import useValidator from "./hooks/useValidator";

export default function InfoWrapper() {
  const [pageIndex, setPageIndex] = useState(0);
  const {
    state: {
      isSigningup,
      subject,

      isLoading,
    },
    actions: { setSigningup },
  } = useContext(DirectPayContext);

  const [formNum, setFormNum] = useState(subject === "igbo" ? 8 : 7);
  const { handleNextPage, handleValidator, handleFormCancel } = useValidator({
    pageIndex,
    setPageIndex,
    formNum,
    setFormNum,
  });
  // Handle browser back/forward button click
  useEffect(() => {
    const handleBackForward = () => {
      if (
        window.history.state &&
        window.history.state.pageIndex !== undefined
      ) {
        if (
          (window.history.state.pageIndex + 1 === 7 ||
            window.history.state.pageIndex + 1 === 6) &&
          isSigningup
        ) {
          setSigningup(false);
        }
        setPageIndex(window.history.state.pageIndex);
      } else {
        setPageIndex(0);
      }
    };

    window.addEventListener("popstate", handleBackForward);

    return () => {
      window.removeEventListener("popstate", handleBackForward);
    };
  }, [isSigningup, setSigningup]);

  return (
    <TrustpilotProvider>
      {isLoading ? (
        <FindingPerfectTutorMatch />
      ) : (
        <div>
          {pageIndex === 0 ? (
            <Landing onClick={handleNextPage} />
          ) : (
            pageIndex >= 1 && (
              <FormLogic
                formNum={formNum}
                handleFormCancel={handleFormCancel}
                handleValidator={handleValidator}
                pageIndex={pageIndex}
              />
            )
          )}
        </div>
      )}
    </TrustpilotProvider>
  );
}
