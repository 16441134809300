export const lessonResources = {
  Yoruba: {
    Child: {
      Beginner: {
        "Introduction & Emotions": {
          name: "Introduction & Emotions",
          resources: {
            grammarSpotlight: ``,
            flashCards:
              "https://quizlet.com/gb/902046110/yoruba-kids-basic-emotion-flash-cards/?funnelUUID=06e5e6c0-1c13-4551-8376-6a11592ae2a4",
            practiceVideo: "",
            sentenceContruction: true
          },
        },
        Greetings: {
          name: "Greetings",
          resources: {
            grammarSpotlight: ``,
            flashCards:
              "https://quizlet.com/gb/901942163/yoruba-kids-level-1-topic-1-greetings-flash-cards/",
            practiceVideo:
              '<iframe width="853" height="480" src="https://www.youtube.com/embed/HJF1Atm9NRo" title="AARO OSAN ALE | Learn how to GREET AT  DIFFERENT TIMES OF THE DAY   | Yoruba for Kidz" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
            sentenceContruction: true
          },
        },
        Introductions: {
          name: "Introductions",
          resources: {
            grammarSpotlight: ``,
            flashCards:
              "https://quizlet.com/gb/901961864/yoruba-kids-level-1-topic-2-introductions-flash-cards/?funnelUUID=7c123ca9-f7db-47bb-8163-9913266e0941",
            practiceVideo:
              '<iframe width="853" height="480" src="https://www.youtube.com/embed/RiJDQnXinvM" title="How do I introduce myself in Yorùbá?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
            sentenceContruction: true
          },
        },
        Manners: {
          name: "Manners",
          resources: {
            grammarSpotlight: ``,
            flashCards:
              "https://quizlet.com/gb/901958598/yoruba-kids-level-1-topic-3-manners-flash-cards/?funnelUUID=d9c569a7-5889-4993-8e16-a8f96cac8bb1",
            practiceVideo:
              '<iframe width="853" height="480" src="https://www.youtube.com/embed/HJF1Atm9NRo" title="AARO OSAN ALE | Learn how to GREET AT  DIFFERENT TIMES OF THE DAY   | Yoruba for Kidz" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
            sentenceContruction: true
          },
        },
        "Core Family": {
          name: "Core Family",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/902000481/yoruba-kids-level-1-topic-4-core-family-flash-cards/?funnelUUID=ca3fd1ce-f4b9-4c95-894e-556b8a5639d6`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1Xb90RdPSYbthjXEGa1UWaB2I1x4N6AA3/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        "Recap (Session 1- 4)": {
          name: "Recap (Session 1- 4)",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/916538560/yoruba-kids-level-consolidation-topic-1-4-flash-cards/?funnelUUID=7b7f5e4d-a05d-4a31-8f25-5c709b45592d`,
            practiceVideo: ``,
          },
        },
        "Close Family": {
          name: "Close Family",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/902175974/yoruba-kids-level-1-topic-5-close-family-flash-cards/?funnelUUID=67112c27-3789-44b4-a644-6a312a663578`,
            practiceVideo: ``,
          },
        },
        Friends: {
          name: "Friends",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/902192069/yoruba-kids-level-1-topic-6-friends-flash-cards/?funnelUUID=04c4b8ea-3a34-4957-be63-c8029b922bce`,
            practiceVideo: ``,
          },
        },
        School: {
          name: "School",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/902197150/yoruba-kids-level-1-topic-7-school-flash-cards/?funnelUUID=271e4e1b-e772-4dc3-bd54-bf6c19135451`,
            practiceVideo: `<iframe width="853" height="480" src="https://www.youtube.com/embed/HJF1Atm9NRo" title="AARO OSAN ALE | Learn how to GREET AT  DIFFERENT TIMES OF THE DAY   | Yoruba for Kidz" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Around the House": {
          name: "Around the House",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/902372950/yoruba-kids-level-1-topic-8-around-the-house-flash-cards/?funnelUUID=a7d1dbf1-f59b-4a7f-902c-9eab264ef46d`,
            practiceVideo: ``,
          },
        },
        "Recap  (Session 5- 8)": {
          name: "Recap  (Session 5- 8)",
          resources: {
            grammarSpotlight: `
                `,
            flashCards: `https://quizlet.com/gb/916539985/yoruba-kids-level-1-consolidation-set-topic-5-8-flash-cards/?funnelUUID=66c2d2cb-83f5-4e68-b412-9ac48d423608`,
            practiceVideo: ``,
          },
        },

        Colours: {
          name: "Colours",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/902375327/yoruba-kids-level-1-topic-9-colours-flash-cards/?funnelUUID=68088d10-3eca-4f5f-b1ce-384786e09b5a`,
            practiceVideo: `<iframe width="853" height="480" src="https://www.youtube.com/embed/HJF1Atm9NRo" title="AARO OSAN ALE | Learn how to GREET AT  DIFFERENT TIMES OF THE DAY   | Yoruba for Kidz" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Clothing: {
          name: "Clothing",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/902377720/yoruba-kids-level-1-topic-10-clothing-flash-cards/?funnelUUID=9fbf80cf-f88f-4f46-8c25-102225f12db8`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/RiJDQnXinvM?si=o3APVllVl5FsRTT6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Numbers: {
          name: "Numbers",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/902381645/yoruba-kids-level-1-topic-11-numbers-flash-cards/?funnelUUID=e080f238-722b-464b-818f-5626dce7eb56`,
            practiceVideo: `<iframe width="853" height="480" src="https://www.youtube.com/embed/HJF1Atm9NRo" title="AARO OSAN ALE | Learn how to GREET AT  DIFFERENT TIMES OF THE DAY   | Yoruba for Kidz" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Parts of the Face": {
          name: "Parts of the Face",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/902379633/yoruba-kids-level-1-topic-12-parts-of-the-face-flash-cards/?funnelUUID=7aa6abe8-b3af-448f-bfd7-4479e60bc3c2`,
            practiceVideo: ``,
          },
        },
        "Recap (Session 9- 12)": {
          name: "Recap (Session 9- 12)",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/916542214/yoruba-kids-level-1-consolidation-set-topic-9-12-flash-cards/?new`,
            practiceVideo: ``,
          },
        },
        Food: {
          name: "Food",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/902558510/yoruba-kids-level-1-topic-13-food-flash-cards/?funnelUUID=a1157ac9-8f7d-44b2-a7fa-33d3fce41be2`,
            practiceVideo: ``,
          },
        },
        Animals: {
          name: "Animals",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/902578642/yoruba-kids-level-1-topic-14-animals-flash-cards/?funnelUUID=dba0cccf-bddb-4b05-a4b1-887c33fcb113`,
            practiceVideo: ``,
          },
        },
        Hobbies: {
          name: "Hobbies",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/902588574/yoruba-kids-level-1-topic-15-hobbies-flash-cards/?funnelUUID=ef8bbba1-f58c-4a90-8383-8636a748a7d7`,
            practiceVideo: `<iframe width="853" height="480" src="https://www.youtube.com/embed/HJF1Atm9NRo" title="AARO OSAN ALE | Learn how to GREET AT  DIFFERENT TIMES OF THE DAY   | Yoruba for Kidz" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Daily Activities": {
          name: "Daily Activities",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/902625146/yoruba-kids-level-1-topic-16-daily-activities-flash-cards/?funnelUUID=b54df49a-fa50-4510-83dc-8536dc3486e8`,
            practiceVideo: ``,
          },
        },
        "Recap (Session 13- 16)": {
          name: "Recap (Session 13- 16)",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/916543778/yoruba-kids-level-1-consolidation-set-topic-13-16-flash-cards/?new`,
            practiceVideo: ``,
          },
        },
      },
    },
    Adult: {
      Beginner: {
        Greetings: {
          name: "Greetings",
          resources: {
            grammarSpotlight: `
              - **'Ẹ'** naturally means You in Yoruba, but it is also an **honorific word**. This means you use **Ẹ** when you are addressing elders as a sign of respect or a group of people.
    For example - When greeting an elder or more than a person in the morning, we say - **E̩** Káàáro̩, but when greeting a friend and younger one, we say - Káàáro̩.  
    When thanking elders, we say **Ẹ** ṣeun, but for a friend and younger one, we say - **O** ṣeun.
              `,
            flashCards:
              "https://quizlet.com/gb/816648315/ikini-ojoojumo-everyday-greetings-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe width="816" height="612" src="https://www.youtube.com/embed/-NKxDuJ4src" title="Topset Lesson Yoruba Beginner Session 1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        "Questions & Introduction": {
          name: "Questions & Introduction",
          resources: {
            grammarSpotlight: `
              'Rẹ̀' means her/him/his/its in Yoruba. So 'ìlú rẹ̀' could mean her city or his city, depending on the context. Sometimes in conversation, the r is omitted and you hear, 'Orúkọ ẹ̀ ni Sinmi' - Her name is Sinmi or Bingo ni orúkọ ajá ẹ̀ - Her dog's name is Bingo. 
PS: Ẹ̀, the shortened form of Rẹ̀ is different from Ẹ - You that we discussed in Session 1
              `,
            flashCards:
              "https://quizlet.com/gb/822612173/words-with-gb-flash-cards/",
            practiceVideo:
              '<iframe width="853" height="480" src="https://www.youtube.com/embed/LvA1JNhSHDw" title="Topset Lesson Yoruba Beginner Session 2" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        Family: {
          name: "Family",
          resources: {
            grammarSpotlight: `
              **The future tense maker - Mà *á*** 

**Mà** ***á*** is an equivalent of 'will' in Yoruba 

Mo **mà** a lọ rí Bàbá ẹ - I will go and see your father.

A **mà a** lọ rí Bàbá ẹ - We will go and see your father.
**Interesting fact** - When saying 'I will' in Yoruba which is 'Mo **mà a'**, Mo (I) can be omitted and you hear or say Mà (I'll). This is grammatically correct, as we have in the conversation practice - 

Mà á lọ rí Bàbá àti Ìyá rẹ láìpẹ́. - I'll go and see your father and mother soon.
              `,
            flashCards:
              "https://quizlet.com/gb/841387880/session-3-family-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe width="853" height="480" src="https://www.youtube.com/embed/mC51sHWsNkM" title="Topset Lesson Yoruba Beginner Session 3" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        "Around the House": {
          name: "Things around the house",
          resources: {
            grammarSpotlight: `
            **Position of Adjectives**

In the English Language, the adjective comes before the noun it describes as in black cup or white bed. However, in Yoruba, the noun will come first before the adjective. 
**Ibùsùn Funfun -** White Bed

**Ìkòkò Dúdú -** Black Pot

**Omi Tutù  -** Cold Water

**Ife Pupa -** Red Cup
            `,
            flashCards: `https://quizlet.com/gb/825840266/words-with-kp-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1Xb90RdPSYbthjXEGa1UWaB2I1x4N6AA3/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        "Recap (Session 1- 4)": {
          name: "Recap (Session 1- 4)",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/896416123/test?answerTermSides=4&promptTermSides=6&questionCount=40&questionTypes=14&showImages=true`,
            practiceVideo: ``,
          },
        },
        "Places and Transportation": {
          name: "Places and Transportation",
          resources: {
            grammarSpotlight: `Prepositions '**Sí**'and '**Ní**' and their uses

**Sí** means to - It is usually used when the verbs suggest movement.  

E.g. Mo n lọ **sí** ilé-ìwé  - I am going to school

Wá **sí** ilé mi - Come to my house

**Ní** means in or at - It is usually used when the verb or object is in a stationary mood. 

Ilé-ìwé mi wà **ni** Ibadan - My school is in Ibadan.

Adé wà **ní** ilé mi - Ade is at my house

*PS: this **Ní** is different from the verb Ní - have*`,
            flashCards: `https://quizlet.com/gb/841436758/places-means-of-transportation-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="853" height="480" src="https://www.youtube.com/embed/Sb2rTTafmiE" title="Topset Lesson Yoruba Beginner Session 5" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Clothing: {
          name: "Clothing",
          resources: {
            grammarSpotlight: `
            **'Ṣé' - The great question maker** 

Ṣé is used to start questions in Yoruba. It means Do, and could also be used for future or continuous tense questions when the right tense makers are present in the questions.

E.g. Q: **Ṣé** o fẹ́ran aṣọ mi? - Do you like my dress?   A: Mo fẹ́ran aṣọ rẹ o - I like your dress

Q: **Ṣé** o fẹ́ irú rẹ́? - Do you want something like it? - A: Mo fẹ́ irú rẹ́.

Q: **Ṣé** o mà wọ? - Will you wear it? - A: Mo mà wọ - I will wear it
            `,
            flashCards: `https://quizlet.com/gb/817139138/colours-awo-flash-cards/?funnelUUID=565b2fbb-8a13-42c3-91bb-0a25db9f99eb`,
            practiceVideo: `<iframe width="853" height="480" src="https://www.youtube.com/embed/cEAxEop4nxc" title="Topset Lesson Yoruba Beginner Session 6" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Parts of the Body": {
          name: "Parts of the Body",
          resources: {
            grammarSpotlight: `**Present/Continuous Tense Maker -Ń - Am/Are/IS**

Ń is used in Yoruba with verbs when expressing an action that is still ongoing.

For example

Mò ń se irun mi - I am doing my hair. 
Ó ń ya irun rẹ́ - He is combing his hair
Ẹ̀yìn ń ro mí - My back aches`,
            flashCards: `https://quizlet.com/gb/841470858/session-7-parts-of-the-body-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="853" height="480" src="https://www.youtube.com/embed/k1TPhgAAIrg" title="Topset Lesson Yoruba Beginner Session 7" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Food: {
          name: "Food",
          resources: {
            grammarSpotlight: `
            **Perfect Tense Maker - Ti - Has/Have/Had**

Want to express yourself in a perfect tense? Then add Ti before the Yoruba verb.

Ẹyin ná ti bàjẹ́. - The egg has expired.
Mo ti jẹun - I have eaten.
Ó ti jẹ ìrẹsì - He has eaten rice.
            `,
            flashCards: `https://quizlet.com/gb/841474009/session-8-food-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="853" height="480" src="https://www.youtube.com/embed/b4C41ZQO0cw" title="Topset Lesson Yoruba Beginner Session 8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Recap  (Session 5- 8)": {
          name: "Recap  (Session 5- 8)",
          resources: {
            grammarSpotlight: `
            `,
            flashCards: `https://quizlet.com/854160071/test?funnelUUID=c59da0bd-dbb6-4000-81a9-f321d19f1cc8`,
            practiceVideo: ``,
          },
        },

        Animals: {
          name: "Animals",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/841479232/session-9-animals-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="853" height="480" src="https://www.youtube.com/embed/APd82umoKNo" title="Topset Lesson Yoruba Beginner Session 9" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Numbers 1-10": {
          name: "Numbers 1-10",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/841482911/yoruba-level-1-session-11-numbers-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="853" height="480" src="https://www.youtube.com/embed/vvXNwMwVIs0" title="Topset Lesson Yoruba Beginner Session 11" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Time: {
          name: "Time",
          resources: {
            grammarSpotlight: `**Ó yẹ kí - Should (it is ideal or good)**

 **'Ó yẹ kí'** is used to express  'should' in Yoruba, the tricky thing about it is that it must start the sentence, even before the subject. 

**Ó yẹ kí** Ade wá lọ́la **** - Ade should come tomorrow

**Ó yẹ kí** á jẹ pizza lóni  - We should eat pizza today`,
            flashCards: `https://quizlet.com/gb/851843683/yoruba-level-1-session-10-time-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="853" height="480" src="https://www.youtube.com/embed/SOC8KIq1lBs" title="Topset Lesson Yoruba Beginner Session 10" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Direction: {
          name: "Direction",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/850721641/yoruba-level-1-session-12-directions-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="853" height="480" src="https://www.youtube.com/embed/I-U0zny0JAM" title="Topset Lesson Yoruba Beginner Session 12" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
      },
      Elementary: {
        Weddings: {
          name: "Weddings",
          resources: {
            grammarSpotlight: ``,
            flashCards:
              "https://quizlet.com/gb/846118792/session-1-wedding-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe width="853" height="480" src="https://www.youtube.com/embed/FMUpmKFdVQs" title="Topset Lesson Yoruba Elementary Session 1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        "Home & Family": {
          name: "Home & Family",
          resources: {
            grammarSpotlight: ``,
            flashCards:
              "https://quizlet.com/gb/847489849/yoruba-level-2-session-2-home-family-flash-cards/",
            practiceVideo:
              '<iframe src="https://drive.google.com/file/d/1eGiDBjrgbXSiZV0NZGjay4NXxPXgM9FH/preview" width="640" height="480" allow="autoplay"></iframe>',
          },
        },
        "Daily Activities": {
          name: "Daily Activities",
          resources: {
            grammarSpotlight: ``,
            flashCards:
              "https://quizlet.com/gb/848459447/session-3-daily-activities-flash-cards/?i=57u171&x=1qqt",
            practiceVideo:
              '<iframe src="https://drive.google.com/file/d/18_arlOt5pHBymX3nYk0Z7LJvMf4Q8P3f/preview" width="640" height="480" allow="autoplay"></iframe>',
          },
        },
        "Work and School": {
          name: "Work and School",
          resources: {
            grammarSpotlight: ``,
            flashCards:
              "https://quizlet.com/899112897/yoruba-level-2-topic-2-work-and-school-draft-flash-cards/",
            practiceVideo:
              '<iframe src="https://drive.google.com/file/d/10zjN9h1eQNtzmBbtAcoK_18qhDeUA7E_/preview" width="640" height="480" allow="autoplay"></iframe>',
          },
        },
        Health: {
          name: "Health",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/848974616/session-4-health-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1v7BHpbDEy1UBBRTG1ttWJM6D25Fe_IU2/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        "Consolidation Quiz (Session 1 - 4)": {
          name: "Consolidation Quiz (Session 1 - 4)",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/876224154/test?answerTermSides=4&promptTermSides=6&questionCount=40&questionTypes=14&showImages=true`,
            practiceVideo: ``,
          },
        },
        Shopping: {
          name: "Shopping",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/849018157/session-5-shopping-flash-cards/?i=57u171&x=1qqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1MNWKDEYgrIRtJLjWvsZEQsDAphzcZmC7/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        Restaurant: {
          name: "Restaurant",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/849555058/session-6-restaurant-flash-cards/?i=57u171&x=1qqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1EAXf91tPgp4kmo37JmRwCz4EOiM-u84B/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        Travel: {
          name: "Travel",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/849998134/session-7-travel-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/10dBtro6eeshxce90TNwQx-qMcEDZe6fi/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        "Numbers 11 - 20": {
          name: "Numbers 11 - 20",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/850037062/session-8-numbers-11-20-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1i8ZZxHeVCfRqQuy4SAtvFtQE2XWGVJ_B/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        "Numbers 13 - 1000": {
          name: "Numbers 13 - 1000",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/899108975/yoruba-level-2-topic-5-numbers-13-1000-draft-flash-cards/?funnelUUID=e04951df-bfd3-4aa5-9895-132bce197299`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1i8ZZxHeVCfRqQuy4SAtvFtQE2XWGVJ_B/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        "Consolidation Quiz (Session 5 - 8)": {
          name: "Consolidation Quiz (Session 5 - 8)",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/876224639/test?answerTermSides=2&promptTermSides=6&questionCount=40&questionTypes=14&showImages=true`,
            practiceVideo: ``,
          },
        },

        "Expressing Emotions": {
          name: "Expressing Emotions",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/850420482/session-9-expressing-emotions-flash-cards/?i=57u171&x=1qqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1wDevdWUzVrT1_Vt3a9iKpPTjNU810udr/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        Culture: {
          name: "Culture",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/850442085/session-10-culture-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/10zjN9h1eQNtzmBbtAcoK_18qhDeUA7E_/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        Weather: {
          name: "Weather",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/851307474/session-11-weather-flash-cards/?i=57u171&x=1qqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1RjW8pr0ijWFrBFvssq6TJruOKvy3_LvU/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        Sports: {
          name: "Sports",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/851362990/session-12-sports-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1acrjCdKp9R07K48xmq5Wp8NTP1ncgx1C/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        "Consolidation Quiz (Session 9 - 12)": {
          name: "Consolidation Quiz (Session 9 - 12)",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/876224933/test?answerTermSides=2&promptTermSides=6&questionCount=40&questionTypes=14&showImages=true`,
            practiceVideo: ``,
          },
        },
      },
    },
  },
  Igbo: {
    Child: {
      Beginner: {
        "Introduction & Manners": {
          name: "Introduction & Manners",
          resources: {
            grammarSpotlight: ``,
            flashCards:
              "https://quizlet.com/gb/901969534/igbo-kids-level-1-topic-3-manners-flash-cards/?funnelUUID=1352b229-42e0-4aa6-b23d-652a91cad3e1",
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/hMu-KCPTOqw?si=2ZqO86AuLSTFiAt2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Greetings: {
          name: "Greetings",
          resources: {
            grammarSpotlight: ``,
            flashCards:
              "https://quizlet.com/gb/901944233/igbo-kids-level-1-topic-2-greetings-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/pKclg25WHjw?si=gR8J8qP2WWzdj98e" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        Introductions: {
          name: "Introductions",
          resources: {
            grammarSpotlight: ``,
            flashCards:
              "https://quizlet.com/gb/901964983/igbo-kids-level-1-topic-3-introductions-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/GZMIjZFiXsg?si=UCUGMduqtxXyiuSQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        Emotions: {
          name: "Emotions",
          resources: {
            grammarSpotlight: ``,
            flashCards:
              "https://quizlet.com/gb/902325480/igbo-kids-level-1-topic-4-basic-emotions-flash-cards/?i=57u171&x=1jqt",
            practiceVideo: "",
          },
        },

        "Recap (Session 1-4)": {
          name: "Recap (Session 1-4)",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/916581361/topic-1-4-consolidation-set-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: ``,
          },
        },
        "Core Family": {
          name: "Core Family",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/901978185/igbo-kids-level-1-topic-4-core-family-flash-cards/?funnelUUID=2ebddb49-5a63-4da8-a324-3cbb9957f2d2`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/EMbGvFOK7xk?si=hcoArW3BXMvS6IEB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Close Family": {
          name: "Close Family",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/902328611/igbo-kids-level-1-topic-5-close-family-flash-cards/?funnelUUID=f925fb48-7c5e-4350-8add-4de5ab80145b`,
            practiceVideo: ``,
          },
        },
        Friends: {
          name: "Friends",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/902337845/igbo-level-1-topic-6-friends-flash-cards/?funnelUUID=7a2f0ba5-6fd7-494d-9abe-2bffb0f7c870`,
            practiceVideo: ``,
          },
        },
        School: {
          name: "School",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/902342185/igbo-kids-level-1-topic-7-school-flash-cards/?funnelUUID=a32c9ce1-241b-40c9-91b8-f54f508b6f47`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/HJF1Atm9NRo?si=2-NvMOBVlb6-gxp6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Around the House": {
          name: "Around the House",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/902344794/igbo-kids-level-1-topic-8-around-the-house-flash-cards/?funnelUUID=342b0990-bd18-481f-90bb-b245c2c19a59`,
            practiceVideo: ``,
          },
        },
        "Recap  (Session 5- 8)": {
          name: "Recap  (Session 5- 8)",
          resources: {
            grammarSpotlight: `
                `,
            flashCards: `https://quizlet.com/854160071/test?funnelUUID=c59da0bd-dbb6-4000-81a9-f321d19f1cc8`,
            practiceVideo: ``,
          },
        },

        Colours: {
          name: "Colours",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/902354474/igbo-kids-level-1-topic-9-colours-flash-cards/?funnelUUID=ae56fc2c-f96c-4347-9ff1-b95a4f1bf0d7`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/HJF1Atm9NRo?si=tavYNWaJNCI09Qa4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Clothing: {
          name: "Clothing",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/902357817/igbo-kids-level-1-topic-10-clothings-flash-cards/?funnelUUID=5050ccd1-5530-455f-a5c0-bb02bebfb5fb`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/RiJDQnXinvM?si=d1Ir6Gm6IoozbbTo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Numbers: {
          name: "Numbers",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/902362263/igbo-kids-level-1-topic-11-numbers-flash-cards/?funnelUUID=dfa30895-94ba-4e45-8a41-1d82cb991e83`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/HJF1Atm9NRo?si=2iQU6ouWWFhBy-8E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Parts of the Face": {
          name: "Parts of the Face",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/902365162/igbo-kids-level-1-topic-12-parts-of-the-face-flash-cards/?funnelUUID=ace91402-2e71-4938-8862-6535389f870b`,
            practiceVideo: ``,
          },
        },
        "Recap (Session 9- 12)": {
          name: "Recap (Session 9- 12)",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/854157642/test?funnelUUID=4c694272-d2c6-4cb4-bed0-7ea267cfc0cd`,
            practiceVideo: ``,
          },
        },
        Food: {
          name: "Food",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/902863705/igbo-kids-level-1-topic-13-food-flash-cards/?funnelUUID=8147fcf6-8d14-4207-90bf-a8cf7c4bcf7f`,
            practiceVideo: ``,
          },
        },
        Animals: {
          name: "Animals",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/902868486/igbo-kids-level-1-topic-14-animals-flash-cards/?funnelUUID=c45fbcaf-6d96-488c-a574-5042334e9a64`,
            practiceVideo: ``,
          },
        },
        "Daily Activities": {
          name: "Daily Activities",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/902877991/igbo-kids-level-1-topic-16-daily-activities-flash-cards/?funnelUUID=27a916cf-500f-49fe-b945-fd2dc9927384`,
            practiceVideo: ``,
          },
        },
        "Recap (Session 13- 16)": {
          name: "Recap (Session 13- 16)",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/854160071/test?funnelUUID=c59da0bd-dbb6-4000-81a9-f321d19f1cc8`,
            practiceVideo: ``,
          },
        },
      },
    },
    Adult: {
      Beginner: {
        Greetings: {
          name: "Greetings",
          resources: {
            grammarSpotlight: ``,
            flashCards:
              "https://quizlet.com/gb/887429044/igbo-level-1-session-1-greetings-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe src="https://drive.google.com/file/d/1MtBp8Gd4wp_CpjgAYbi3-DDmYgqbLvQ3/preview" width="640" height="480" allow="autoplay"></iframe>',
          },
        },
        "Questions & Introduction": {
          name: "Questions & Introduction",
          resources: {
            grammarSpotlight: ``,
            flashCards:
              "https://quizlet.com/gb/880014874/igbo-level-1-session-2-questions-and-introduction-new-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe src="https://drive.google.com/file/d/1biNNvflKapkXPQ_Rb4OhgbxsLPylgSEc/preview" width="640" height="480" allow="autoplay"></iframe>',
          },
        },
        Family: {
          name: "Family",
          resources: {
            grammarSpotlight: ``,
            flashCards:
              "https://quizlet.com/gb/842888567/session-3-family-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/U6Bn8gzN62Y?si=GdF21GKW55PEne3S" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        "Things Around the House": {
          name: "Things around the house",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/892411898/igbo-level-1-topic-4-things-around-the-house-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1kuyxH3TcdXmKc7_YI9PZk6oP_LU9X0r9/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        "Consolidation Quiz (Session 1 - 4)": {
          name: "Consolidation Quiz (Session 1 - 4)",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/896421741/test?answerTermSides=2&promptTermSides=6&questionCount=40&questionTypes=14&showImages=true`,
            practiceVideo: ``,
          },
        },
        "Places and Transportation": {
          name: "Places and Transportation",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/843055507/session-5-places-and-transportation-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/mJ7YiOlTwAE?si=xzl-ozdXr71DjQIx" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Clothing: {
          name: "Clothing",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/843066215/igbo-session-6-clothing-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/mtvTyG3mCA4?si=-V8II1OEVcGOWuJf" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Parts of the Body": {
          name: "Parts of the Body",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/843539651/igbo-level-1-session-7-parts-of-the-body-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/4NCwH-Izp2g?si=WP81F5ZEGnq0V049" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Food: {
          name: "Food",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/843545312/igbo-level-1-session-8-food-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/24618Fs2-fU?si=l87o7KBRFVS--wO1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Consolidation Quiz (Sessions 5- 8)": {
          name: "Consolidation Quiz (Sessions 5- 8)",
          resources: {
            grammarSpotlight: `
            `,
            flashCards: `https://quizlet.com/test/igbo-level-1-session-5-8-consolidation-set-new-888528619?answerTermSides=2&promptTermSides=6&questionCount=40&questionTypes=14&showImages=true`,
            practiceVideo: ``,
          },
        },

        Animals: {
          name: "Animals",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/843546614/igbo-level-1-session-9-animals-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/tb3QSfNOHNo?si=g3juXbTv-gwmZQus" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Numbers: {
          name: "Numbers",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/843547517/igbo-level-1-session-11-numbers-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/RV4OXImgTeg?si=wfLCKtg33pdA0LPa" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Time: {
          name: "Time",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/851867549/igbo-level-1-session-10-time-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/nxRDK8xHpu4?si=OctxqkeHOE8YY22M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Direction: {
          name: "Direction",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/851865132/igbo-level-1-session-12-directions-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/3xWX0bBGrHo?si=7MuxsbhLnTY12DO9" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Consolidation Quiz (Sessions 9- 12)": {
          name: "Consolidation Quiz (Sessions 9- 12)",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/test/igbo-level-1-session-9-12-consolidation-set-new-888536409?answerTermSides=2&promptTermSides=6&questionCount=40&questionTypes=14&showImages=true`,
            practiceVideo: ``,
          },
        },
      },
      Elementary: {
        Wedding: {
          name: "Wedding",
          resources: {
            grammarSpotlight: ``,
            flashCards:
              "https://quizlet.com/gb/849546452/igbo-level-2-session-1-wedding-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe src="https://drive.google.com/file/d/1tbJL9pwDh6ULWY8-39DZV7YWsOyTbHJw/preview" width="640" height="480" allow="autoplay"></iframe>',
          },
        },
        "Home & Family": {
          name: "Home & Family",
          resources: {
            grammarSpotlight: ``,
            flashCards:
              "https://quizlet.com/gb/849546706/igbo-level-2-session-2-home-family-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe src="https://drive.google.com/file/d/1fMNnVzVBfSuXzkAXvDSjp8M1nlZQdPRQ/preview" width="640" height="480" allow="autoplay"></iframe>',
          },
        },
        Family: {
          name: "Family",
          resources: {
            grammarSpotlight: ``,
            flashCards:
              "https://quizlet.com/gb/849546706/igbo-level-2-session-2-home-family-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe src="https://drive.google.com/file/d/1fMNnVzVBfSuXzkAXvDSjp8M1nlZQdPRQ/preview" width="640" height="480" allow="autoplay"></iframe>',
          },
        },
        "Daily Activities": {
          name: "Daily Activities",
          resources: {
            grammarSpotlight: ``,
            flashCards:
              "https://quizlet.com/gb/849546801/igbo-level-2-session-3-daily-activities-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe src="https://drive.google.com/file/d/1vnc9KS7LnuvRU97KJ4ZKwV3klmlMaxE1/preview" width="640" height="480" allow="autoplay"></iframe>',
          },
        },
        "Work & School": {
          name: "Work & School",
          resources: {
            grammarSpotlight: ``,
            flashCards:
              "https://quizlet.com/929247952/igbo-level-2-topic-2-work-and-school-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/JZM2Gc1YDZ0?si=YYLiu4grYKOtEvzA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        Health: {
          name: "Health",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/849546857/igbo-level-2-session-4-health-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1JOtT09Rj3hx67ES7PaBr4PW2BLY0xbSP/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        "Consolidation Quiz (Session 1 - 4)": {
          name: "Consolidation Quiz (Session 1 - 4)",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/876228901/test?answerTermSides=2&promptTermSides=6&questionCount=40&questionTypes=14&showImages=true`,
            practiceVideo: ``,
          },
        },
        Shopping: {
          name: "Shopping",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/858060038/igbo-level-2-session-6-restaurant-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1BYpSyeZGg1VxyKrVqsjiqd2N6Jr4vK7E/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        Restaurant: {
          name: "Restaurant",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/858060038/igbo-level-2-session-6-restaurant-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1SEACVy_bbop6zc1xoAl3nYaDalMgKTuP/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        Travel: {
          name: "Travel",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/862007670/igbo-level-2-session-7-travel-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1V5RfMhmqMtBp-G2tdwRDirb-incxnQfh/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        "Numbers 11 - 20": {
          name: "Numbers 11 - 20",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/862007736/igbo-level-2-session-8-numbers-11-20-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1B-Bwn2esZDxygCjSfaS5516hlzmlGkfu/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        "Numbers 11+": {
          name: "Numbers 11+",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/862007736/igbo-level-2-session-8-numbers-11-20-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1B-Bwn2esZDxygCjSfaS5516hlzmlGkfu/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        "Consolidation Quiz (Session 5 - 8)": {
          name: "Consolidation Quiz (Session 5 - 8)",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/876229227/test?answerTermSides=2&promptTermSides=6&questionCount=40&questionTypes=14&showImages=true`,
            practiceVideo: ``,
          },
        },

        "Expressing Emotions": {
          name: "Expressing Emotions",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/862007837/igbo-level-2-session-9-expressing-emotions-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1_CqczGCSMNxOP8R_oXP6twftunocdzrc/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        Culture: {
          name: "Culture",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/862007892/igbo-level-2-session-10-culture-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1_2H9Q5CoekKpQriy7yhvbocHBTVCOwtW/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        Weather: {
          name: "Weather",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/862007944/igbo-level-2-session-11-weather-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1QJCGpc9_T_krstxdSSrb3GJZ0Vn1UY9T/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        Sports: {
          name: "Sports",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/862007990/igbo-level-2-session-12-sports-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1-0oSfrGDrWc8UdVI56eGQ2jOlKqTSRgp/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        "Sports and Hobbies": {
          name: "Sports and Hobbies",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/gb/862007990/igbo-level-2-session-12-sports-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1-0oSfrGDrWc8UdVI56eGQ2jOlKqTSRgp/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        "Consolidation Quiz (Session 9 - 12)": {
          name: "Consolidation Quiz (Session 9 - 12)",
          resources: {
            grammarSpotlight: ``,
            flashCards: `https://quizlet.com/876229402/test?answerTermSides=2&promptTermSides=4&questionCount=40&questionTypes=14&showImages=true`,
            practiceVideo: ``,
          },
        },
      },
    },
  },
};

export interface LessonResources {
  [key: string]: {
    [key: string]: {
      [key: string]: {
        [key: string]: {
          name: string;
          resources: {
            grammarSpotlight: string;
            flashCards: string;
            practiceVideo: string;
          };
        };
      };
    };
  };
}
