import { XIcon, MenuIcon } from "@heroicons/react/solid";
import { useState } from "react";
import clsx from "clsx";

import { useNavigate } from "react-router-dom";
import { useStateValue } from "src/context/StateProvider";
import UserProfile from "src/components/Shared/Header/UserProfile";
import MobileNav from "../DashboardMobileNav";
const logo = `${process.env.REACT_APP_ASSET_CDN}/logo.webp`;
const mobile_logo = `${process.env.REACT_APP_ASSET_CDN}/logo.webp`;

function Header() {
  const navigate = useNavigate();
  const [{ learningAppLoading, showMobileMenu }, dispatch] = useStateValue();

  const toggleMenu = async () => {
    await dispatch({
      type: "SET_SHOW_MOBILE_MENU",
      showMobileMenu: !showMobileMenu,
    });
  };

  const onSwitchToLearningClick = async () => {
    await dispatch({
      type: "SET_LEARNING_APP_LOADING",
      learningAppLoading: true,
    });
    navigate("/");
  };

  return (
    <header
      className="top-0 z-50 
    bg-pale-purple-bg py-5 sticky border-b-2"
    >
      {/* Top Bar */}
      <div className="flex justify-between px-6">
        {/* left */}
        <div>
          <div
            className="lg:flex items-center h-10 
            cursor-pointer hidden"
          >
            <img src={logo} alt="" width={190} height={150} />
          </div>
          <div
            className="relative flex items-center h-10 
            cursor-pointer my-auto lg:hidden"
            onClick={() => navigate("/")}
          >
            <img src={mobile_logo} alt="" width={160} />
          </div>
        </div>

        {/* right */}
        {/* Logged In Profile  */}
        <div>
          <div className="lg:flex items-center space-x-6 hidden">
            {/* <div>
              <p
                className="text-sm text-primary hover:text-secondary cursor-pointer font-semibold"
                onClick={onSwitchToLearningClick}
              >
                Switch to Learning
              </p>
            </div> */}
            <UserProfile />
          </div>
        </div>
        {/* Burger Icon */}
        <div className="flex lg:hidden">
          <div
            className={clsx({
              block: !showMobileMenu,
              hidden: showMobileMenu,
            })}
          >
            <MenuIcon
              onClick={toggleMenu}
              className="inline-flex h-10 w-10 text-black lg:hidden cursor-pointer"
            />
          </div>
          <div
            className={clsx({
              block: showMobileMenu,
              hidden: !showMobileMenu,
            })}
          >
            <XIcon
              onClick={toggleMenu}
              className="inline-flex h-10 w-10 text-black lg:hidden cursor-pointer"
            />
          </div>
        </div>
      </div>
      {/* Mobile Menu */}
      <div
        className={clsx({
          block: showMobileMenu,
          hidden: !showMobileMenu,
        })}
      >
        <div className="w-full px-6 bg-pale-purple-bg absolute lg:hidden h-screen">
          <MobileNav />
        </div>
      </div>
    </header>
  );
}

export default Header;
