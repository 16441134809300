import React, { ReactNode } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { StudentApi } from "src/api/student.api";
import { useStateValue } from "src/context/StateProvider";
import { routes } from "src/Routes";

interface IPracticeSectionProps {
  title: string;
  icon?: ReactNode;
  description: string;
  link?: string;
  id?: number;
}
export default function Resources({
  title,
  icon,
  description,
  link,
  id,
}: IPracticeSectionProps) {
  const [{ user }] = useStateValue();

  const navigate = useNavigate();
  const { level, topic, order, topicId, curriculumId, parentDivisionId } =
    useParams();

  const handleNavigate = () => {
    if (id === 4) {
      // for sentence construction
      navigate(
        `/${routes.SENTENCE_CONSTRUCTION_SELECTION.url}/${level}/${order}/${topic}/${topicId}/${curriculumId}/${parentDivisionId}`
      );
    } else if (link) {
      window.open(link, "_blank");
    } else {
      navigate(
        `${routes.STUDENT_DASHBOARD_LEARN.url}/${level}/${order}/${topic}/${title}`,
        {
          state: { id },
        }
      );
    }
    if (topicId) StudentApi.updateTopicLastPracticed(user?._id, { topicId });
  };

  return (
    <section
      className="py-5 px-3 border-b-2 border-gray-200 cursor-pointer hover:bg-pale-purple-bg transition-all duration-200"
      onClick={handleNavigate}
    >
      <div className="items-center gap-[10px]">
        <span className="inline-block bg-gray-100 rounded-[9px] p-2">
          {icon}
        </span>
        <p className="font-semibold text-xl">{title}</p>
      </div>
      <p className="mt-[10px]">{description}</p>
    </section>
  );
}
