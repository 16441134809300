import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "src/components/StudentDashboard/Learn/Breadcrumb";
import { ReactComponent as Star } from "src/assets/icons/star.svg";
import { ReactComponent as FlashCards } from "src/assets/icons/flashcard.svg";
import { ReactComponent as Video } from "src/assets/icons/video.svg";
import { ReactComponent as Game } from "src/assets/icons/game-pad.svg";
import { useStateValue } from "src/context/StateProvider";
import { useParams } from "react-router-dom";
import {
  LessonResources,
  lessonResources,
} from "src/static-data/lessonResources";
import Resources from "src/components/StudentDashboard/Learn/Resources";
import EmptyList from "src/components/Shared/EmptyList";

const practiceSessions = [
  {
    id: 1,
    title: "Grammar Spotlight",
    icon: <Star />,
    description:
      "Revise your conversation practice video to get more fluent in holding conversations about greetings.",
  },
  {
    id: 2,
    title: "Quizlet Sets",
    icon: <FlashCards />,
    description:
      "Practice your words on Greetings with your lesson quizlet FlashCards!",
  },
  {
    id: 3,
    title: "Practice Video",
    icon: <Video />,
    description:
      "Revise your conversation practice video to get more fluent in holding conversations about greetings.",
  },
  {
    id: 4,
    title: "Sentence Construction Exercises",
    icon: <Game />,
    description:
      "Play the sentence constructor game to construct different sentences with  the words you’ve learnt!",
  },
];

export default function TopicDetails() {
  const [{ userLessonProgress }] = useStateValue();
  const { level, topic } = useParams();
  const [hasSessions, setHasSessions] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    // Check if any sessions are rendered
    const sessionsExist = practiceSessions.some((session) => {
      const flashCards = (lessonResources as LessonResources)?.[
        userLessonProgress?.curriculum
      ]?.[userLessonProgress.ageGroup]?.[level as any]?.[topic as any]
        ?.resources.flashCards;
      const grammarSpotlight = (lessonResources as LessonResources)?.[
        userLessonProgress?.curriculum
      ]?.[userLessonProgress.ageGroup]?.[level as any]?.[topic as any]
        ?.resources.grammarSpotlight;
      const practiceVideo = (lessonResources as LessonResources)?.[
        userLessonProgress?.curriculum
      ]?.[userLessonProgress.ageGroup]?.[level as any]?.[topic as any]
        ?.resources.practiceVideo;

      return (
        (session.id === 1 && grammarSpotlight) ||
        (session.id === 2 && flashCards) ||
        (session.id === 3 && practiceVideo) ||
        session.id === 4
      );
    });

    setHasSessions(sessionsExist);
  }, [userLessonProgress, level, topic]);

  console.log("userLessonProgress", userLessonProgress);

  return (
    <section className="text-primary">
      <Breadcrumb />
      <div className="mt-8 md:w-1/2">
        {practiceSessions.map((session) => {
          const flashCards = (lessonResources as LessonResources)?.[
            userLessonProgress?.curriculum
          ]?.[userLessonProgress.ageGroup]?.[level as any]?.[topic as any]
            ?.resources.flashCards;
          const grammarSpotlight = (lessonResources as LessonResources)?.[
            userLessonProgress?.curriculum
          ]?.[userLessonProgress.ageGroup]?.[level as any]?.[topic as any]
            ?.resources.grammarSpotlight;
          const practiceVideo = (lessonResources as LessonResources)?.[
            userLessonProgress?.curriculum
          ]?.[userLessonProgress.ageGroup]?.[level as any]?.[topic as any]
            ?.resources.practiceVideo;

          if (session.id === 1 && grammarSpotlight) {
            return (
              <Resources
                id={session.id}
                key={session.id}
                title={session.title}
                icon={session.icon}
                description={session.description}
              />
            );
          }
          if (session.id === 2 && flashCards) {
            return (
              <Resources
                id={session.id}
                key={session.id}
                title={session.title}
                icon={session.icon}
                description={session.description}
                link={flashCards}
              />
            );
          }
          if (session.id === 3 && practiceVideo) {
            return (
              <Resources
                id={session.id}
                key={session.id}
                title={session.title}
                icon={session.icon}
                description={session.description}
              />
            );
          }
          if (session.id === 4) {
            return (
              <Resources
                id={session.id}
                key={session.id}
                title={session.title}
                icon={session.icon}
                description={session.description}
              />
            );
          }
          return null;
        })}
      </div>
      {!hasSessions && (
        <EmptyList message="No resources available for this topic" />
      )}
    </section>
  );
}
