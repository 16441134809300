import { dialects } from "src/context/DirectPayContext";
import Dropdown from "./Dropdown";
import HeaderText from "./HeaderText";
import { Context as DirectPayContext } from "src/context/DirectPayContext";
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import axios from "axios";
import debounce from "src/utils/helper/debounceFunc";
import { QueryDialectApi } from "src/api/dialects-query.api";
import DialectDropdown from "./DialectDropdown";

interface IDialectForm {
  alt?: boolean;
  onClick?: () => void;
}
export interface IDialect {
  value: string;
  label: string;
}
export interface IDialects {
  match: string;
  type: string;
  towns: string[];
  state: string;
}

export default function DialectForm({ alt, onClick }: IDialectForm) {
  const {
    state: { selectedDialect },
    actions: { setDialect },
  } = useContext(DirectPayContext);
  const [dialectQuery, setdialectQuery] = useState("");
  const [dialects, setdialects] = useState<IDialects[]>([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const fetchDialects = useCallback(
    debounce(async (query: string) => {
      setOpen(true);
      setLoading(true);

      try {
        const response = await QueryDialectApi.queryDialect(query);
        const returnedDialects: IDialects[] = response.data;
        setdialects(returnedDialects);
      } catch (error) {
        console.error("Error fetching dialect:", error);
      }
      setLoading(false);
    }, 300),
    []
  );

  useEffect(() => {
    if (dialectQuery.length >= 3) {
      fetchDialects(dialectQuery);
    } else {
      setdialects([]);
    }
  }, [dialectQuery, fetchDialects]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setdialectQuery(e.target.value);
  };

  const handleValidator = () => {
    if (onClick) onClick();
  };

  return (
    <>
      <div className="flex  flex-col items-center">
        <HeaderText>
          (Optional) Please select the Igbo dialect you want to learn
        </HeaderText>
        <p className=" mt-5 max-w-2xl font-semibold text-secondary ">
          You can search for a specific state, local government area (LGA), or
          town
        </p>
        <div className="max-w-sm w-full">
          <DialectDropdown
            items={dialects}
            selectedItem={selectedDialect}
            setSelectedItem={setDialect}
            dialectQuery={dialectQuery}
            handleInputChange={handleInputChange}
            loading={loading}
            open={open}
            setOpen={setOpen}
          />
        </div>
      </div>
      {alt && (
        <button onClick={handleValidator} className="pink-button px-20 mt-14">
          {selectedDialect.match ? "Continue" : "Skip"}
        </button>
      )}
    </>
  );
}
