export default function TrustpilotBadge() {
  return (
    <div
      className="trustpilot-widget"
      data-locale="en-US"
      data-template-id="5419b637fa0340045cd0c936"
      data-businessunit-id="636e6461cdcaa579cfeb9b1b"
      data-style-height="20px"
      data-style-width="100%"
      data-theme="light"
    >
      <a
        href="https://www.trustpilot.com/review/topset.app"
        target="_blank"
        rel="noopener noreferrer"
      ></a>
    </div>
  );
}
