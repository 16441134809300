import RevolutCheckout, { RevolutCheckoutInstance } from "@revolut/checkout";
import Lottie from "lottie-react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import { PaymentApi } from "src/api/payment.api";
import * as loadingAnimation from "src/assets/animations/rose-loader.json";
import InvoicePayment from "src/components/Checkout/InvoicePayment";
import { Instance } from "src/interfaces/payment";
import Header from "src/layouts/DashboardHeader";
import { routes } from "src/Routes";

import { OnBoardingApi } from "src/api/onboarding.api";
import CardInfo from "src/components/Checkout/CardInfo";
import InvalidPaymentLink from "src/components/Checkout/InvalidPaymentLink";
import PaymentMade from "src/components/Checkout/PaymentMade";
import PaymentPlan from "src/components/Checkout/PaymentPlan";
import PreppingDashboard from "src/components/LottieAnimations/PreppingDashboard";
import { Context as DirectPayContext } from "src/context/DirectPayContext";
import { useStateValue } from "src/context/StateProvider";
import { getClientCurrency } from "src/services/currency";
import initStripe from "src/utils/stripeUtils";
import { LessonApi } from "src/api/lesson.api";
import Swal from "sweetalert2";
const logo = `${process.env.REACT_APP_ASSET_CDN}/logo.webp`;
const mobile_logo = `${process.env.REACT_APP_ASSET_CDN}/logo.webp`;

const Revolut = () => {
  const navigate = useNavigate();
  // Use the useLocation hook to get the location object.
  const location = useLocation();
  const route = useMemo(() => new URL(window.location.href), []);
  const {
    state: {
      onBoarding,
      isLoading,
      lessonFrequency,
      lessonSchedule,
      isBookingMoreLessons,
    },
    actions: { bookLessons },
  } = useContext(DirectPayContext);
  // Parse the query parameters from the location object.
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location]
  );

  // Get the value of the 'invoiceId' parameter.
  // Get the value of the 'paymentPlan' parameter.
  const invoiceId = useMemo(
    () => searchParams.get("invoiceId"),
    [searchParams]
  );
  const learningPackage = useMemo(
    () => searchParams.get("learningPackage"),
    [searchParams]
  );
  const [{ clientIpCountry }] = useStateValue();

  const currency = useMemo(() => {
    let currency = getClientCurrency(clientIpCountry);
    return !["USD", "GBP", "CAD"].includes(currency!.currencyCode)
      ? "USD"
      : currency!.currencyCode;
  }, [clientIpCountry]);

  const userId = useMemo(() => searchParams.get("userId"), [searchParams]);
  const [loading, setLoading] = useState(true);
  const [isBookingLessonsForInvoice, setIsBookingLessonsForInvoice] =
    useState(false);
  const [paymentComplete, setPaymentComplete] = useState(false);
  const [invalidLink, setInvalidLink] = useState(false);
  const [order, setOrder] = useState<any>(null);
  const [iframeLoading, setIframeLoading] = useState(false);

  const createRevolutOrder = useCallback(async () => {
    try {
      if (invoiceId || learningPackage) {
        let response;
        if (invoiceId) {
          response = await PaymentApi.createRevolutOrder({ invoiceId });
          setOrder(response.data);
        } else if (learningPackage) {
          response = await PaymentApi.createSubscription({
            learningPackageId: learningPackage,
            userId: userId,
            lessonFrequency,
            lessonSchedule,
            currency,
          });
          return Array.isArray(response.data?.payments)
            ? response.data?.payments[0]
            : response.data?.payments;
        }
        console.log("order created");
        return 1;
      } else {
        setInvalidLink(true);

        return 0;
      }
    } catch (error) {
      setInvalidLink(true);

      console.log("caught error: ", error);
      return 0;
    }
  }, [
    currency,
    invoiceId,
    learningPackage,
    lessonFrequency,
    lessonSchedule,
    userId,
  ]);

  const getPaymentOrder = useCallback(async () => {
    try {
      if (invoiceId) {
        let response;
        if (invoiceId) {
          response = await PaymentApi.getPaymentOrder({ invoiceId });
        }
        console.log("order fetched");
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.log("caught error: ", error);
      return [];
    }
  }, [invoiceId]);

  const updateRevolutOrder = useCallback(
    async (state: "PENDING" | "FAILED" | "COMPLETE") => {
      try {
        const response = await PaymentApi.updateRevolutOrder({
          paymentId: order?._id,
          reference: invoiceId || learningPackage,
          state,
        });
        if (learningPackage && userId) {
          if (
            onBoarding!.onboarding?.leadFunnel === "direct-to-pay-flow-1" ||
            onBoarding!.onboarding?.leadFunnel === "direct-to-pay-flow-3" ||
            isBookingMoreLessons
          ) {
            if (response?.data?.state === "COMPLETE") {
              await bookLessons(order?.subscriptionId, onBoarding);
            }
          } else {
            await OnBoardingApi.updateOnBoardingDetails(
              { paymentStatus: "COMPLETE" },
              onBoarding!.onboarding?._id
            );
          }
        } else if (invoiceId) {
          if (response?.data?.state === "COMPLETE") {
            setIsBookingLessonsForInvoice(true);
            await LessonApi.autoBookLesson({
              invoiceId,
            }).catch((error) => {
              let message =
                error?.response?.data?.message || "An error occurred";

              Swal.fire({
                color: "#341A64",
                title: `<strong>Unable to book lessons</strong>`,
                html: `  <p>${message}. Please contact our <a href="mailto:info@topset.app" style="text-decoration: underline;" >support</a> team for assistance.</p>`,
                confirmButtonText: `OK`,
                confirmButtonColor: "#D33479",
              });
            });
            setIsBookingLessonsForInvoice(false);
          }
        }
        // setOrder(response.data);
        console.log("order updated");
      } catch (error) {
        console.log("caught error: ", error);
      }
    },
    [
      order,
      invoiceId,
      learningPackage,
      userId,
      onBoarding,
      isBookingMoreLessons,
      bookLessons,
    ]
  );

  useEffect(() => {
    //call endpoint to initiate order creation
    const init = async () => {
      if (searchParams.get("stripe") === "success") {
        await updateRevolutOrder("COMPLETE");
        navigate(
          `${routes.CHECKOUT_THANK_YOU.url}?amount=${searchParams.get(
            "amount"
          )}&currency=${searchParams.get("currency")}`
        );

        return;
      }

      if (invoiceId) {
        const payments = await getPaymentOrder();
        if (payments.length === 0) {
          await createRevolutOrder();
        } else if (payments.length >= 1) {
          if (payments[0].state === "COMPLETE") {
            setPaymentComplete(true);
          } else {
            setOrder(payments[0]);
          }
        }
      } else if (learningPackage && userId) {
        const payment = await createRevolutOrder();

        if (Object.keys(onBoarding).length === 0) {
          return navigate(routes.LOGIN.url);
        }

        if (payment.state === "COMPLETE") {
          setPaymentComplete(true);
        } else {
          setOrder(payment);
        }
      } else {
        setInvalidLink(true);
      }
      setLoading(false);
    };

    init();
  }, [createRevolutOrder, getPaymentOrder, invoiceId, learningPackage, userId]);

  const handleRevolutPopup = () => {
    switch (order?.gateway) {
      case "STRIPE":
        stripeCheckout();
        break;

      default:
        RevolutCheckout(
          order.publicId || order.token,
          `${
            route.host === "learn.topset.app" || route.host === "topset.app"
              ? "prod"
              : "sandbox"
          }`
        ).then((instance: Instance | RevolutCheckoutInstance) => {
          setTimeout(() => {
            setIframeLoading(false);
          }, 2000);
          // work with instance
          instance.payWithPopup({
            async onSuccess() {
              setLoading(true);
              await updateRevolutOrder("COMPLETE");
              if (invoiceId) {
                navigate(routes.CHECKOUT_THANK_YOU.url, {
                  state: {
                    order,
                  },
                });
              } else if (learningPackage) {
                navigate(routes.CHECKOUT_PAYMENT_CONFIRMATION.url, {
                  state: {
                    order,
                  },
                });
              }
            },
            savePaymentMethodFor: "merchant",
            onError(message) {
              if (order.state !== "COMPLETE") updateRevolutOrder("FAILED");
            },
            onCancel() {
              instance.destroy();
            },
          });
        });
    }
  };

  async function stripeCheckout() {
    const stripe = await initStripe();
    if (stripe) {
      stripe
        .redirectToCheckout({
          // lineItems: [
          // 	{
          // 		price: order?.stripeOrderId,
          // 		quantity: 1,
          // 	},
          // ],
          // mode: "payment",
          // successUrl: `${
          // 	process.env.REACT_APP_CURRENT_DOMAIN
          // }/checkout?stripe=success&amount=${
          // 	order?.amount?.value as string
          // }&currency=${order?.amount?.currency}`,
          // cancelUrl: `${process.env.REACT_APP_CURRENT_DOMAIN}/checkout?stripe=failed`,
          // customerEmail: order?.invoice?.email,

          sessionId: order.gateway.sessionId,
        })
        .then((result) => {
          if (result.error) {
            // Handle error here
            console.error(result.error.message);
          } else {
            // Trigger your function here
            // onCheckoutSuccess();
          }
        });
      // console.warn(error.message);
    }
  }

  const handleRevolutPaymentRequest = useCallback(() => {
    RevolutCheckout(
      order.publicId || order.token,
      `${
        route.host === "learn.topset.app" || route.host === "topset.app"
          ? "prod"
          : "sandbox"
      }`
    ).then((instance: Instance | RevolutCheckoutInstance) => {
      const paymentRequest = instance.paymentRequest({
        target: document.getElementById("revolut-payment-request")!,

        async onSuccess() {
          setLoading(true);
          await updateRevolutOrder("COMPLETE");
          if (invoiceId) {
            navigate(routes.CHECKOUT_THANK_YOU.url, {
              state: {
                order,
              },
            });
          } else if (learningPackage) {
            navigate(routes.CHECKOUT_PAYMENT_CONFIRMATION.url, {
              state: {
                order,
              },
            });
          }
        },
        savePaymentMethodFor: "merchant",
        onError(error) {
          console.log(`Error: ${error.message}`);
          // if (order.state !== "COMPLETE") updateRevolutOrder("FAILED");
        },
        buttonStyle: { size: "small", variant: "light" },
      });
      paymentRequest.canMakePayment().then((method: any) => {
        setLoading(false);
        if (method) {
          paymentRequest.render();
        } else {
          console.log("Not supported");
          paymentRequest.destroy();
        }
      });
    });
  }, [
    order,
    route.host,
    updateRevolutOrder,
    invoiceId,
    learningPackage,
    navigate,
  ]);

  useEffect(() => {
    if (order) {
      handleRevolutPaymentRequest();
    }
  }, [order, handleRevolutPaymentRequest]);

  if (isLoading || isBookingLessonsForInvoice) {
    return <PreppingDashboard isBookingLessons={isBookingLessonsForInvoice} />;
  }
  console.log("");
  return (
    <div className="text-primary h-screen">
      <div className="hidden sm:block relative  ">
        <Header />
      </div>
      <Helmet>
        <title>TopSet | Checkout</title>
        <meta name="description" content="Online tutoring" />
        <meta name="keywords" content="Online tutoring" />
      </Helmet>
      {/* <SuspenseLoader /> */}
      {loading && (
        <Lottie
          animationData={loadingAnimation}
          style={{
            height: 150,
            width: 150,
          }}
          className="absolute hidden sm:block top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        />
      )}
      <div className="relative z-10 pb-8 px-8">
        <div className="flex items-center sm:flex-row flex-col justify-between p-6 sm:hidden  ">
          <div
            className="lg:flex items-center h-10 
            cursor-pointer hidden"
            onClick={() => (window.location.href = "http://topset.app/")}
          >
            <img src={logo} alt="" width={190} height={150} />
          </div>
          <div
            className="relative flex items-center h-10 
            cursor-pointer my-auto lg:hidden"
            onClick={() => (window.location.href = "http://topset.app/")}
          >
            <img src={mobile_logo} alt="" width={160} />
          </div>
          <div className="flex items-center  sm:hidden ">
            <p>
              Powered by{" "}
              <span className="font-bold text-xl text-black">Revolut</span>
            </p>
          </div>
        </div>

        {!invalidLink && !paymentComplete && order && (
          <>
            <div className="container"></div>
            <section className="text-center z-10 container md:mt-12 px-8 mt-4 left-8 right-8 ">
              <h1 className="font-bold md:text-2xl text-2xl leading-10 max-w-3xl mx-auto ">
                Almost There! Secure Your Spot to Mastery
              </h1>
              <p className="font-medium text-secondary text-lg md:text-lg leading-7 mt-4 max-w-4xl mx-auto ">
                Complete your checkout and embark on a journey to fluency with
                our expert tutors
              </p>
            </section>
            <div
              className={`flex gap-5 container  mt-10 relative z-10 sm:flex-row flex-col ${
                loading && "hidden"
              } `}
            >
              {invoiceId ? (
                <InvoicePayment order={order} />
              ) : (
                <PaymentPlan
                  onBoarding={onBoarding}
                  setInvalidLink={setInvalidLink}
                  order={order}
                  bookLessons={bookLessons}
                  setOrder={setOrder}
                />
              )}
              <CardInfo
                handleRevolutPopup={handleRevolutPopup}
                iframeLoading={iframeLoading}
                setIframeLoading={setIframeLoading}
              />
            </div>
          </>
        )}
        {paymentComplete && <PaymentMade />}
        {invalidLink && <InvalidPaymentLink dp={learningPackage} />}
      </div>

      <div className="sm:flex items-center fixed bottom-0   hidden  w-full justify-end px-6  pb-2 ">
        <p>
          Powered by
          <span className="font-bold text-xl ">
            {` ${order?.gateway}` ?? " Revolut"}
          </span>
        </p>
      </div>
    </div>
  );
};

export default Revolut;
