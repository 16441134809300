import Subject from "./Subject";
import Tutor from "./Tutor";
interface IProfile {
  customer: any;
}
export default function Profile({ customer }: IProfile) {
  return (
    <div className="white-card-rounded p-4 flex-1 ">
      <p className="text-gray-400 ">Customer</p>
      <h1 className="font-semibold text-xl">
        {customer?.firstName} {customer?.lastName}
      </h1>
      <div className="border border-gray-300 my-2"></div>

      <div>
        <h1 className="font-bold text-lg">Potential Tutors</h1>
        {customer.tutors.map((tutor: any) => (
          <Tutor key={tutor?.tutorId} tutor={tutor} />
        ))}
      </div>
      <div className="border border-gray-300 my-4"></div>
      <div>
        <h1 className="font-bold text-lg">Your Order</h1>
        {customer.subjects.map((subject: any) => (
          <Subject key={subject.subjectId} subject={subject} />
        ))}

        <div className="flex justify-between items-center gap-4 mt-2 flex-wrap">
          <p className="flex-1">Promos:</p>
          <p className="font-semibold flex-1 text-right">
            {customer?.promo && customer.promo.title}
          </p>
        </div>
      </div>
      <div className="border border-gray-300 my-2"></div>
      <div className="flex justify-between items-center text-xl font-bold">
        <h1>Total</h1>
        <p>
          {Intl.NumberFormat("en-US", {
            style: "currency",
            currency: customer?.lessonPrice?.currencyCode,
          }).format(customer?.lessonPrice?.amount)}
        </p>
      </div>
    </div>
  );
}
