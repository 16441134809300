import { useContext, useEffect, useRef, useState } from "react";
import TrustpilotBadge from "src/components/Shared/TrustpilotBadge";
import { Context as DirectPayContext } from "src/context/DirectPayContext";
import { useTrustpilot } from "src/context/TrustpilotContext";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../carousel.css";
import TestimonialPlayerModal from "../VideoPlayer";
import { responsive } from "src/static-data/direct-to-pay/carousel-responsiveness";
import {
  igboTestimonials,
  yorubaTestimonials,
} from "src/static-data/direct-to-pay/testimonials";
import { IoPlayCircleOutline } from "react-icons/io5";

type ITestimonials = {
  name: string;
  videoUrl: string;
  thumbnail?: string;
};

interface ILanding {
  onClick: () => void;
}
export default function Landing({ onClick }: ILanding) {
  const {
    state: { subject, source },
  } = useContext(DirectPayContext);
  const { isScriptLoaded } = useTrustpilot();
  const videoRefs = useRef<HTMLVideoElement[]>([]);
  const [testimonials, setTestimonials] = useState<ITestimonials[]>([]);
  const [isVideoModalOpen, setisVideoModalOpen] = useState(false);
  const [videoDetails, setVideoDetails] = useState<ITestimonials>({
    name: "",
    videoUrl: "",
  });
  const handlePlayClick = (videoDets: ITestimonials) => {
    setVideoDetails(videoDets);
    setisVideoModalOpen(true);
  };
  useEffect(() => {
    if (subject == "yoruba") {
      setTestimonials([...yorubaTestimonials]);
    } else {
      setTestimonials([...igboTestimonials]);
    }
  }, []);
  return (
    <>
      <div>
        <TestimonialPlayerModal
          videoDetails={videoDetails}
          isVideoModalOpen={isVideoModalOpen}
          setisVideoModalOpen={setisVideoModalOpen}
        />
      </div>
      <section className="text-center h-screen pt-5 sm:absolute top-1/2 sm:-translate-y-1/2 left-8 right-8 ">
        <h1 className="font-bold text-2xl sm:text-2xl pb-5 leading-10 max-w-xl mx-auto">
          <span>{subject === "yoruba" ? "E̩ Káàbò̩" : "Ndeewo"}</span>
          <br />
          Welcome to TopSet
        </h1>
        <p
          className="font-medium  leading-7 max-w-xl mx-auto "
          style={{
            textShadow: `
            -1px -1px 0 #fff,  
     1px -1px 0 #fff,
    -1px  1px 0 #fff,
     1px  1px 0 #fff
            `,
          }}
        >
          <span className="text-secondary font-semibold text-2xl">
            Ready to meet your{" "}
            <span> {subject === "yoruba" ? "Yoruba" : "Igbo"}</span> tutor?
          </span>{" "}
          <br />{" "}
          <span className="text-secondary max-w-xl">
            In just a few questions, we'll reveal the perfect match for you!
          </span>
        </p>
        <div className="flex justify-center items-center flex-col mb-12 md:flex-row  gap-2">
          {isScriptLoaded && (
            <div className="mt-7">
              <TrustpilotBadge />
            </div>
          )}
          <button data-test="d2pGetStartedButton" onClick={onClick} className="pink-button mt-8">
            Get Started
          </button>
        </div>

        <div className="max-w-6xl mx-auto  ">
          <Carousel
            swipeable={true}
            draggable={false}
            showDots={false}
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={4000}
            keyBoardControl={true}
            customTransition="all 2.5s"
            transitionDuration={500}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            className="text-center"
          >
            {testimonials.map((testimonial, index) => (
              <div key={index} className="relative inline-block">
                <img
                  src={testimonial.thumbnail}
                  alt={`Thumbnail for ${testimonial.videoUrl}`}
                  style={{ objectPosition: "left center" }}
                  className=" w-[350px] h-[210px] bg-black rounded-3xl object-cover"
                />
                <div
                  className="absolute bottom-1/2 left-[44%]"
                  onClick={() => {
                    handlePlayClick(testimonial);
                  }}
                >
                  <IoPlayCircleOutline className="text-white text-5xl" />
                </div>
                <p className="text-md text-center font-normal text-primary mt-2">
                  {testimonial.name}
                </p>
              </div>
            ))}
          </Carousel>
        </div>
      </section>

      {/* <div className="absolute hidden md:block md:left-28 lg:left-40 top-28">
        <img
          src={`${process.env.REACT_APP_ASSET_CDN}/direct-pay-landing-image4.webp`}
          alt="direct-pay-landing-image4"
          className=" rounded-full w-32 h-32 object-cover border-primary border-2 p-1 border-dashed"
        />
      </div>
      <div className="absolute hidden md:block md:right-24 lg:right-48 top-28">
        <img
          src={`${process.env.REACT_APP_ASSET_CDN}/direct-pay-landing-image1.webp`}
          alt="direct-pay-landing-image1"
          className=" rounded-full w-32 h-32 object-cover border-primary border-2 p-1 border-dashed"
        />
      </div>
      <div className="absolute hidden md:block left-20 bottom-40">
        <img
          src={`${process.env.REACT_APP_ASSET_CDN}/direct-pay-landing-image2.webp`}
          alt="direct-pay-landing-image2"
          className=" rounded-full w-28 h-28 object-cover border-primary border-2 p-1 border-dashed"
        />
      </div>
      <div className="absolute hidden md:block md:right-32 lg:right-56 bottom-28">
        <img
          src={`${process.env.REACT_APP_ASSET_CDN}/direct-pay-landing-image3.webp`}
          alt="direct-pay-landing-image3"
          className=" rounded-full w-28 h-28 object-cover border-primary border-2 p-1 border-dashed"
        />
      </div> */}
    </>
  );
}
